const top100StudentsPoolB = [
  {
    "": "",
    "name": "Madhav Manoj",
    "email": "drdivyamanoj8634@gmail.com",
    "phone": "551081267"
  },
  {
    "": "",
    "name": "ARADHYA KUMARI",
    "email": "phuspadevi245@gmail.com",
    "phone": "6009740407"
  },
  {
    "": "",
    "name": "Animesh Mishra",
    "email": "ajaymishra1891@gmail.com",
    "phone": "6386178094"
  },
  {
    "": "",
    "name": "Raj Anand",
    "email": "rajanandkataruka@outlook.com",
    "phone": "7003415492"
  },
  {
    "": "",
    "name": "Ayush Patel",
    "email": "ayushpatel26012009@gmail.com",
    "phone": "7008156643"
  },
  {
    "": "",
    "name": "Arhum Gandhi",
    "email": "arhumg@gmail.com",
    "phone": "7021608627"
  },
  {
    "": "",
    "name": "Navraj singh",
    "email": "shivrajsingh22334455@gmail.com",
    "phone": "7023486829"
  },
  {
    "": "",
    "name": "Abhishek Sagar",
    "email": "abhisheksagar1729@gmail.com",
    "phone": "7209703603"
  },
  {
    "": "",
    "name": "Chinmaygupta",
    "email": "seemagupta08874@gmail.com",
    "phone": "7296861858"
  },
  {
    "": "",
    "name": "S Santhosh",
    "email": "jsnarayanan08@gmail.com",
    "phone": "7305631459"
  },
  {
    "": "",
    "name": "Arnav Gupta",
    "email": "anuraggupta1120@gmail.com",
    "phone": "7317759894"
  },
  {
    "": "",
    "name": "sarthak jain",
    "email": "sarthakdebu@gmail.com",
    "phone": "7727010439"
  },
  {
    "": "",
    "name": "aryan singh",
    "email": "suman8090710932@gmail.com",
    "phone": "7839858513"
  },
  {
    "": "",
    "name": "Manish Nayak",
    "email": "manishgudul2622@gmail.com",
    "phone": "7846815180"
  },
  {
    "": "",
    "name": "Himanshi rathore",
    "email": "Himanshirathore125@gmail.com",
    "phone": "7877470112"
  },
  {
    "": "",
    "name": "Akshit Shivhare",
    "email": "seemakshit.1111@gmail.com",
    "phone": "7999091511"
  },
  {
    "": "",
    "name": "Alisha",
    "email": "neelamsharma84@yahoo.com",
    "phone": "8005616417"
  },
  {
    "": "",
    "name": "AYUSH UTSAV",
    "email": "ayushutsav9873@gmail.com",
    "phone": "8076635527"
  },
  {
    "": "",
    "name": "Daksh shah",
    "email": "legend.shah777@gmail.com",
    "phone": "8305768181"
  },
  {
    "": "",
    "name": "Suryansh Mittal",
    "email": "monimit172@gmail.com",
    "phone": "8427791225"
  },
  {
    "": "",
    "name": "Aarna Saxena",
    "email": "shivasaxenaarna@gmail.com",
    "phone": "8439813354"
  },
  {
    "": "",
    "name": "utkrishna sohgaura",
    "email": "uman4279diwedi@gmail.com",
    "phone": "8719051369"
  },
  {
    "": "",
    "name": "Milan raj",
    "email": "Milanraj1709@gmail.com",
    "phone": "8797774077"
  },
  {
    "": "",
    "name": "Saanvi Singh",
    "email": "rohitash1574@gmail.com",
    "phone": "8808199991"
  },
  {
    "": "",
    "name": "Tejaswa",
    "email": "tejaswadr2008@gmail.com",
    "phone": "8840414383"
  },
  {
    "": "",
    "name": "Sarvesh Dwivedi",
    "email": "7377.sarvesh@gmail.com",
    "phone": "8840912105"
  },
  {
    "": "",
    "name": "Shivaansh Saxena",
    "email": "shivaanshsaxena387@gmail.com",
    "phone": "8968814766"
  },
  {
    "": "",
    "name": "SAMUDITA KUMARI",
    "email": "umeshprasad77gomia@gmail.com",
    "phone": "9006890356"
  },
  {
    "": "",
    "name": "Akshra Walia",
    "email": "waliaakshra@gmail.com",
    "phone": "9015286051"
  },
  {
    "": "",
    "name": "Garima Jain",
    "email": "ggoutamjain@gmail.com",
    "phone": "9,023,252,511"
  },
  {
    "": "",
    "name": "Dhanyaa aasai",
    "email": "aasaithambi41@gmail.com",
    "phone": "9024913380"
  },
  {
    "": "",
    "name": "Sayan Kumar",
    "email": "brijeshigit2001@gmail.com",
    "phone": "9040260770"
  },
  {
    "": "",
    "name": "Sayan Kumar",
    "email": "brijeshigit2001@gmail.com",
    "phone": "9040260770"
  },
  {
    "": "",
    "name": "Aman",
    "email": "aman1308207@gmail.com",
    "phone": "9050419085"
  },
  {
    "": "",
    "name": "Gautam Anand",
    "email": "gautamanandshs77@gmail.com",
    "phone": "9060218081"
  },
  {
    "": "",
    "name": "Parth Maheshwary",
    "email": "ma79ash@gmail.com",
    "phone": "9109134111"
  },
  {
    "": "",
    "name": "Manikant kumar",
    "email": "manikantkumar.shs@gmail.com",
    "phone": "9135501119"
  },
  {
    "": "",
    "name": "Amogh kumar",
    "email": "umeshgupta2408@gmail.com",
    "phone": "9198081556"
  },
  {
    "": "",
    "name": "Yatharth Yadav",
    "email": "yatharth.s0009760@spsrohini.net",
    "phone": "9213434380"
  },
  {
    "": "",
    "name": "Piyush",
    "email": "piyushkumar9488@gmail.com",
    "phone": "9300280081"
  },
  {
    "": "",
    "name": "Avika Bansal",
    "email": "mayank.bansal@nxp.com",
    "phone": "9312207856"
  },
  {
    "": "",
    "name": "Darsani",
    "email": "darsani.kedia@gmail.com",
    "phone": "9339346446"
  },
  {
    "": "",
    "name": "Vihan Agrawal",
    "email": "vihaanagrawalindia@gmail.com",
    "phone": "9406989999"
  },
  {
    "": "",
    "name": "Kunal Gidwani",
    "email": "Gidwani2012@gmail.com",
    "phone": "9414279895"
  },
  {
    "": "",
    "name": "Sanchit choudhary",
    "email": "sanchitchoudhary455@gmail.com",
    "phone": "9415357959"
  },
  {
    "": "",
    "name": "Abhishek  Munde",
    "email": "abhishekamunde2007@gmail.com",
    "phone": "9423867004"
  },
  {
    "": "",
    "name": "SHIV Shankar",
    "email": "shivs975@gmail.com",
    "phone": "9452452985"
  },
  {
    "": "",
    "name": "Dhairya garg",
    "email": "dhairyagarg747@Gmail.com",
    "phone": "9478553852"
  },
  {
    "": "",
    "name": "Shagun  from Chaubeys",
    "email": "vineetchaubey55@gmail.com",
    "phone": "9519335034"
  },
  {
    "": "",
    "name": "Sanjoli Gupta",
    "email": "sanjoli1806@gmail.com",
    "phone": "9593021700"
  },
  {
    "": "",
    "name": "Kashvi Bhalothia",
    "email": "bhalothiakashvi1@gmail.com",
    "phone": "9602374202"
  },
  {
    "": "",
    "name": "Anant Baibhav",
    "email": "kamna.ranjan@gmail.com",
    "phone": "9631818363"
  },
  {
    "": "",
    "name": "Aditri",
    "email": "chatterjee.aditri11@gmail.com",
    "phone": "9650208074"
  },
  {
    "": "",
    "name": "Arv Patel",
    "email": "arv1311patel@gmail.com",
    "phone": "9824058900"
  },
  {
    "": "",
    "name": "Samyak Dubey",
    "email": "samyak.dubey23@gmail.com",
    "phone": "9871535684"
  },
  {
    "": "",
    "name": "Sachin Kalakoti",
    "email": "sachinkalakoti1@gmail.com",
    "phone": "9891901174"
  },
  {
    "": "",
    "name": "Roopesh T",
    "email": "roopeshthulasidoss@gmail.com",
    "phone": "9916343956"
  },
  {
    "": "",
    "name": "Tanishk Singhal",
    "email": "swetag0409@gmail.com",
    "phone": "9926684810"
  },
  {
    "": "",
    "name": "Zaki m",
    "email": "sheikhkazi26@gmail.com",
    "phone": "9928695319"
  },
  {
    "": "",
    "name": "Akhilesh Yadav",
    "email": "leelearamyadav136@gmail.com",
    "phone": "9929491213"
  },
  {
    "": "",
    "name": "Raushan",
    "email": "raushanraj.shs@gmail.com",
    "phone": "9939558190"
  },
  {
    "": "",
    "name": "khush arora",
    "email": "kkhusharorah23@gmail.com",
    "phone": "9971125585"
  },
  {
    "": "",
    "name": "Meenakshi Nair",
    "email": "meenakshi.nair.2009@gmail.com",
    "phone": "9995487833"
  },
  {
    "": "",
    "name": "Arshiya",
    "email": "arshiya28052008@gmail.com",
    "phone": "9996082070"
  },
  {
    "": "",
    "name": "Aishwarya Purohit",
    "email": "purohit.aishwarya08@gmail.com",
    "phone": "7067932723"
  },
  {
    "": "",
    "name": "utkarsh agrawal",
    "email": "vikash.nid@gmail.com",
    "phone": "8317067932"
  },
  {
    "": "",
    "name": "Kartikeya Agarwal",
    "email": "kdabral369@gmail.com",
    "phone": "8474935811"
  },
  {
    "": "",
    "name": "Ronak Jain",
    "email": "jainronak9826@gmail.com",
    "phone": "8989060250"
  },
  {
    "": "",
    "name": "Archit Bindlish",
    "email": "Rekhabindlish@gmail.com",
    "phone": "9466643934"
  },
  {
    "": "",
    "name": "dheeraj",
    "email": "mittalpoonam743@gmail.com",
    "phone": "9467354832"
  },
  {
    "": "",
    "name": "Vedansh Kumar",
    "email": "gayatriraghuwanshi27@gmail.com",
    "phone": "9630359375"
  },
  {
    "": "",
    "name": "Ojasvit Pasricha",
    "email": "pasrichaojasvit08@gmail.com",
    "phone": "9651955127"
  },
  {
    "": "",
    "name": "Aishwary Raghuvanshi",
    "email": "ravi.anshu.kaushal@gmail.com",
    "phone": "9717172254"
  },
  {
    "": "",
    "name": "Niranjan Hosmani",
    "email": "niranjanhosmani37@gmail.com",
    "phone": "9752807553"
  },
  {
    "": "",
    "name": "Ishan Bansal",
    "email": "shilpiigoel@gmail.com",
    "phone": "9792865444"
  },
  {
    "": "",
    "name": "Ayush Goyal",
    "email": "Goyalak79@gmail.com",
    "phone": "9999147706"
  },
  {
    "": "",
    "name": "Vikas Meena",
    "email": "kuldeepmeena705595@gmail.com",
    "phone": "7055954373"
  },
  {
    "": "",
    "name": "Aman Meena",
    "email": "jasvantsingh054@gmail.com",
    "phone": "8650138221"
  },
  {
    "": "",
    "name": "Shantanu Deshmukh",
    "email": "psdesh1june@gmail.com",
    "phone": "8767573546"
  },
  {
    "": "",
    "name": "SANJOLI GUPTA",
    "email": "sanjoli1806@gmail.com",
    "phone": "9593021700"
  },
  {
    "": "",
    "name": "SAANVI SINGH",
    "email": "rohitash1574@gmail.com",
    "phone": "8808199991"
  },
  {
    "": "",
    "name": "RIBHAV TOMAR",
    "email": "info@klischool.com",
    "phone": "8979595500"
  },
  {
    "": "",
    "name": "Raghav Dadhich",
    "email": "rdadhich712@gmail.com",
    "phone": "7014775837"
  },
  {
    "": "",
    "name": "ARYAN SINGH",
    "email": "suman8090710932@gmail.com",
    "phone": "7839858513"
  },
  {
    "": "",
    "name": "Arnav gupta",
    "email": "supralaver66@gmail.com",
    "phone": "9935961764"
  },
  {
    "": "",
    "name": "ANIMESH MISHRA",
    "email": "ajaymishra1891@gmail.com",
    "phone": "6386178094"
  },
  {
    "": "",
    "name": "AARVI MAHESHWARI",
    "email": "manoj.mm655@gmail.com",
    "phone": "8233283161"
  }
]
  
  export default top100StudentsPoolB;