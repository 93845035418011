// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAAaDEexKVAiZh6R4-9VCEbXgqjARf3BSY",
  authDomain: "tosc23-79fed.firebaseapp.com",
  projectId: "tosc23-79fed",
  storageBucket: "tosc23-79fed.appspot.com",
  messagingSenderId: "261859199197",
  appId: "1:261859199197:web:fe23c427d47ce7fd413334"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export function FirebaseApp(){
    return initializeApp(firebaseConfig);
}
const storage = getStorage(app);
export {storage};