import React, { useEffect, useState } from 'react';
import jsonDatapoolA from "./poolAtop100";
import jsonDatapoolB from "./poolBtop100";
import jsonDatapoolC from "./poolCtop100";
import "./result.css";

const Poolresult = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [result, setResult] = useState('');
  const [jsonData, setjsonData] = useState([]);
  useEffect(() => {
    if(props.pool === "A"){
      setjsonData(jsonDatapoolA);
    }
    else if (props.pool === "B")
    {
      setjsonData(jsonDatapoolB);
    }
    else{
      setjsonData(jsonDatapoolC);
    }
  }, []);
  
  const checkTop100 = () => {
    const foundIndex = jsonData.findIndex(
      item => item.phone === inputValue || item.email === inputValue
    );
    
    if (foundIndex !== -1) {
      const foundItem = jsonData[foundIndex];
      setResult(props.pool === "A" ? `Congrats, ${foundItem.name}! You have secured Rank ${foundIndex + 1} in Phase 1.`: `Congrats, ${foundItem.name}! You have secured a place among Top 100 students and you are qualified for Phase 2`);
    } else {
      setResult(props.pool === "A" ? `You are not among top 100 students. Try again next year!` : `You are not among top 100 students and thus not qualified for Phase 2. Try again next year!`);
    }
  };

  return (
    <div className="top100-container">
      <img className = "logo" src="/Vector.png"/>
      <h2 className= "heading" style={{textAlign: "center", color: "white"}}>Pool {props.pool} Result</h2>
      <label className="resultlabel">
       &nbsp; Enter Phone or Email: &nbsp;&nbsp;
          <br/>
        <br/>
        <input
          type="text"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
      </label>
      <button className="check-button" onClick={checkTop100}>
        Check
      </button>
      <div className="result-container">{result && <p className="result-text">{result}</p>}</div>
    </div>
  );
};

export default Poolresult;
