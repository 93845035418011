// import React, {useRef,useState} from 'react'
// import {Link} from 'react-router-dom'
// import {Form, Button} from 'react-bootstrap'
// import { useAuth } from '../context/AuthContext';
// import { TextField } from "@mui/material";
// import { styled } from '@mui/material/styles';
// import logo from './Userlayout/TOSC_white.png';
// import Snackbar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/material/Alert';
// import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
// const Alert = React.forwardRef(function Alert(props, ref) {
//     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
//   });
// const CssTextField = styled(TextField)({
//     '& .MuiInputBase-root.Mui-disabled': {
//       backgroundColor: '#f0f0f0',
//       color:'#000'
//     },
//     '& label.Mui-focused': {

//       color: 'white',
//       input:{
//         color:"white"
//     }
//     },
//     '& label.Mui': {

//         color: 'white',
//         input:{
//           color:"white"
//       }
//       },
//     '& .MuiInput-underline:after': {

//       borderBottomColor: 'white',
//       color:'white',
//       input:{
//         color:"white"
//     }
//     },
//     '& .MuiFormLabel-root': {

//         borderBottomColor: 'white',
//         color:'grey',
//         input:{
//           color:"white"
//       }
//       },
//     '& .MuiOutlinedInput-root': {

//         input:{

//             color:"white"
//         },
//       '& fieldset': {
//         borderColor: 'grey',
//         color:'white',

//       },
//       '&:hover fieldset': {
//         borderColor: 'grey',
//         color:'white',
//       },
//       '&.Mui-focused fieldset': {
//         borderColor: 'white',
//         color:'white',
//         input:{

//             color:"white"
//         },
//       },
//       '&.MuiInputBase-input fieldset': {

//         borderColor: 'white',
//         color:'white',
//         input:{
//             color:"white"
//         }
//       },
//     },
//   });

// export default function ForgotPassword() {

//     const [open, setOpen] = React.useState(true);
//     const emailRef = useRef();
//     const {resetPassword} = useAuth();
//     const [error, setError] = useState('');
//     const [loading,setLoading] = useState(false);
//     const [message, setMessage] = useState('');
//     const [openMessage, setOpenMessage] = React.useState(true);
//     // const navigate = useNavigate();
//     // for closing by the button
//     const handleClose = (event, reason) => {
//       if (reason === 'clickaway') {
//         return;
//       }
//       setOpen(false);
//     };
//     const handleCloseMessage = (event, reason) => {
//       if (reason === 'clickaway') {
//         return;
//       }
//       setOpenMessage(false);
//     };
//     async function handleSubmit(e){
//         e.preventDefault();

//         try{
//             setOpen(false)
//             setOpenMessage(false)
//             setError('')
//             setMessage('')
//             setLoading(true)
//             await resetPassword(emailRef.current.value);
//             setMessage(`Check your email's inbox and spam folder for further notifications.`)
//             setOpenMessage(true)
//             // navigate("/login")
//         } catch{
//             setOpen(true)
//             setError('Failed to reset password. Check your email and try again.') 
//         }
//         setLoading(false)

//     }
//   return (
//     <div>
//     <div className="forgot-gl" style={{height:'400px'}}>
//     <a href = "https://tosc.techkriti.org"> <img src={logo} className="tosc_img tosc_img_signup" alt="tosc logo" target="_blank"/></a>
//       {loading?<Box sx={{ display: 'block',textAlign:'center',marginTop:'100px' }}><CircularProgress 
//       /></Box>:
//       <>
//       <h2 className="text-center mb-4">
//             Reset Password
//         </h2>
//             {error && 
//             <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
//               <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>{error}
//               </Alert>
//             </Snackbar>}
//             {message && 
//             <Snackbar open={openMessage} autoHideDuration={6000} onClose={handleClose}>
//               <Alert onClose={handleCloseMessage} severity="success" sx={{ width: '100%' }}>{message}
//               </Alert>
//             </Snackbar>}
//           <div style={{textAlign: "center"}}>
//             <Form onSubmit={handleSubmit}>
//                 <Form.Group id="email">
//                     <Form.Label>Email</Form.Label>
//                     <CssTextField label="Email" type="email" size="small" inputRef={emailRef} sx={{paddingLeft:'10px', paddingTop:'-10px', marginBottom:2}} />  
//                 </Form.Group>
//                 <Button disabled={loading} type="submit" className="w-100 mt-3 button-5">Reset Password</Button>
//             </Form>
//           </div>
//           {/* login button */}
//           <div style={{textAlign:'center',marginTop: '10px'}}>
//             <Link to="/login" className="button-5 forgot-button" style={{textDecoration:'none', margin: 'auto',color:'black', backgroundColor:'#33b5dc'}}>Login</Link>
//           </div>  
//           {/* //signup button */}
//           <div style={{textAlign:'center',marginTop: '10px'}}>
//             Haven't registered yet?<Link to="/signup" className="button-5 forgot-button" style={{marginLeft:'5px',textDecoration:'none', margin: 'auto',color:'black'}}>Register</Link>
//           </div> 
//         </>
//           }

//     </div>
//     </div>
//   )
// }


import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import logo from "./logo1.png";
// import logo1 from "./light.png";
// import svg from "./logo2.png";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { AlignHorizontalCenter, BorderAllRounded } from "@mui/icons-material";
import { alignProperty } from "@mui/material/styles/cssUtils";

const Forget = ({theme}) => {
    const [credentials, setCredentials] = useState({
			email: "",
			password: "",
			cpassword: "",
			otp: "",
    });
    const [generatedOTP, setGeneratedOTP] = useState("");
    const [showOTP, setShowOTP] = useState(false);
    const [disableGenerateOTP, setDisableGenerateOTP] = useState(false);
    const [inputpassword, setinputpassword] = useState(false);
    let navigation = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        const {email } = credentials;
        const res = await fetch("https://toscbackend.onrender.com/api/getuserbyemail",
        {
					method: "POST",
					headers: {
							"Content-Type": "application/json",
					},
					body: JSON.stringify({ email }),
        });
        const resjson = await res.json();
        if(!(resjson.success)){
					alert("No user found with this email. Please enter a valid email");
					return;
        }
        const response = await fetch(
            "https://toscbackend.onrender.com/api/generateotpforrecovery",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email }),
            }
        );
        const json = await response.json();
        //console.log(json);
        if (json.success) {
                setGeneratedOTP(json.otp);
                alert("OTP generated successfully and sent to your email!");
                setShowOTP(true);
                setDisableGenerateOTP(true);
                setTimeout(() => {
                    setDisableGenerateOTP(false);
                }, 30000);
               
        } else {
            alert("Invalid credentials");
        }
    };

    const handleVerify = (e) => {
        //e.preventDefault();
        const enteredOtp = credentials.otp; // doubt
        if (parseInt(enteredOtp) === parseInt(generatedOTP)) {
            // OTPs match, create user
            setGeneratedOTP("");
            setinputpassword(true);
            // var notify = window.confirm("OTP Verified and account creaated Successfully!\nEnable email notifications about upcoming events?");
            // const { name, email, password } = credentials;
            // fetch("https://toscbackend.onrender.com/api/auth/createuser", {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/json",
            //     },
            //     body: JSON.stringify({ name, email, password, notify }),
            // })
            //     .then((response) => response.json())
            //     .then((json) => {
            //         console.log(json);
            //         if (json.success) {
            //             // Save the auth token and redirect
            //             localStorage.setItem("token", json.authtoken);
            //             history.push("/");
            //         } else {
            //             alert("Invalid credentials");
            //         }
            //     })
            //     .catch((error) => console.error(error));
        } else {
            // OTPs don't match
            alert("Incorrect OTP, please try again.");
        }
    };
    const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
    const handlechangepass= () => {
        // event.preventDefault();
        const {email, password} = credentials;
        fetch("https://toscbackend.onrender.com/api/changepass", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.errors) {
              setError(data.errors[0].msg);
              setSuccess(false);
            } else {
              setSuccess(true);
              navigation("/login");
              setError(null);
            }
          })
          .catch((error) => console.error(error));
      };
    const onchange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };
    function click() {
        // toggle the type attribute
        const togglePassword = document.querySelector("#togglePassword");
        const passwordV = document.querySelector("#Password");
        const type = passwordV.getAttribute("type") === "password" ? "text" : "password";
        togglePassword.className === 'fa fa-eye viewpass mr-4 text-muted' ? document.getElementById("togglePassword").className = 'fa fa-eye-slash viewpass mr-4 text-muted' : document.getElementById("togglePassword").className = 'fa fa-eye viewpass mr-4 text-muted';
        passwordV.setAttribute("type", type);

    }

    const style = {
        backgroundColor: theme == 1 ? "#0a1929" : "",
        color: "white",
    };

	return (
		<><div><h1 style={{color:"#76ddff", marginTop:"100px",marginBottom:"0px",textAlign:"center"}}>Reset your password</h1></div>
		<div style={style}>
			<div class="everything">
					<div class="hero-img">
						<div>
							<form >
								<div className="mb-3">
									<label htmlFor="email" className="form-label" >
										Email address &nbsp; &nbsp;
									</label>
									<input 
										type="email"
										className="form-control"
										id="email"
										name="email"
										onChange={onchange}
										aria-describedby="emailHelp"
									></input><br/><br/>
									<div id="emailHelp" className="form-text">
										We'll never share your email with anyone
										else.<br/><br/>
									</div>
								</div>
									{false && <div className="mb-3" style={{ position: "relative" }}>
										<label htmlFor="Password" className="form-label">
											Enter Password
										</label>
										<input
											type="password"
											className="form-control"
											id="Paassword"
											name="paassword"
											onChange={onchange}
											style={{ paddingRight: "40px" }} // add padding to make space for the icon
										/>
										<span
											className="fa fa-eye viewpass mr-4 text-muted"
											onClick={click}
											id="togglePassword"
											style={{
												position: "absolute",
												top: "56%",
												right: "10px",
												transform: "translateY(-50%)",
												cursor: "pointer",
											}}
										></span>
											<div id="emailHelp" className="form-text">
												(Minimum 5 characters)
										</div>
									</div>}
                                      
									<button style={{backgroundColor:"#76ddff" ,fontFamily:"monospace",height:"30px" ,width:"150px",borderRadius:"7px", alignSelf:"center"}}
										type="submit"
										className="btn btn-primary"
										onClick={handleSubmit}
										disabled={disableGenerateOTP}
									>
										{disableGenerateOTP
											? "Wait for 30s before generating new otp"
											: "Verify email"}
									</button><br/><br/>

									{showOTP && (
										<div className="mb-3">
											<label
												htmlFor="cPassword"
												className="form-label"
											>
												OTP (Check your spam if you do not see
												the email) &nbsp; &nbsp;
											</label>
											<input
												type="password"
												className="form-control"
												id="otp"
												name="otp"
												onChange={onchange}
											></input>
										</div>
									)}<br/>
									{showOTP && (
										<button style={{backgroundColor:"#76ddff" ,fontFamily:"monospace",height:"30px" ,width:"150px",borderRadius:"7px"}}
											type="button"
											className="btn btn-primary"
											onClick={() =>
												handleVerify(
													generatedOTP,
													setGeneratedOTP
												)
											}
										>
											Verify Otp
										</button>
									)}<br/><br/>
									{inputpassword && <div className="mb-3" style={{ position: "relative" }}>
										<label htmlFor="Password" className="form-label">
											OTP Verified! Enter new Password &nbsp; &nbsp;
										</label>
										<input
											type="password"
											className="form-control"
											id="Password"
											name="password"
											onChange={onchange}
											style={{ paddingRight: "40px" }} // add padding to make space for the icon
										/>
											
										<span
											className="fa fa-eye viewpass mr-4 text-muted"
											onClick={click}
											id="togglePassword"
											style={{
												position: "absolute",
												top: "56%",
												right: "10px",
												transform: "translateY(-50%)",
												cursor: "pointer",
											}}
										></span>
											<div id="emailHelp" className="form-text" >
												(Minimum 5 characters) 
										</div><br/>
									</div>}
									{inputpassword && (
										<button style={{backgroundColor:"#76ddff" ,fontFamily:"monospace",height:"30px" ,width:"150px",borderRadius:"7px"}}
											type="button"
											className="btn btn-primary"
											onClick={handlechangepass}
										>
											Update Password
										</button>
									)}
							</form>
						</div>
					</div>
				</div>
			<div class="social">
				<a>Connect with us:</a>
				<a href="https://facebook.com" class="btnSocial">
						<i
							class="fab fa-2x fa-facebook-f"
							style={{ color: "blue" }}
						></i>
				</a>
				<a href="https://twitter.com" class="btnSocial">
					<i
						class="fab fa-2x fa-twitter"
						style={{ color: "blue" }}
					></i>
				</a>
				<a href="https://instagram.com" class="btnSocial">
					<i
						class="fab fa-2x fa-instagram"
						style={{ color: "blue" }}
					></i>
				</a>
			</div>
			<script src="https://cdn.jsdelivr.net/npm/typed.js@2.0.12"></script>
			</div>
		</>
	);
};

export default Forget;
