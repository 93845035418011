import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import '../styles.css';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../context/AuthContext'
import React, { useState, useEffect } from 'react'
import logo from './TOSC_white.png';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import PermIdentityRoundedIcon from '@mui/icons-material/PermIdentityRounded';
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
const drawerWidth = 240;
const useStyles = makeStyles({
  menu: {
    "& .MuiPaper-root": {
      backgroundColor: "#1E2738",
      width: '200px'
    }
  },
  flexGrow: {
    flex: '1',
  },
  button: {
    color: '#fff',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#3c52b2',
    },
  }
});
function Sidenavbar(props) {

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const bgnavbar = {
    borderRight: '0px',
    paddingRight: '0px',
  }

  const drawer = (
    <div><a href="https://tosc.techkriti.org" target="_blank" rel="noreferrer"><img alt='' src={logo} className='logo' /></a>
      <Divider style={bgnavbar} />
      <List style={bgnavbar}>
        <Link to="dashboard" style={{ textDecoration: 'none' }}><ListItem >
          <ListItemButton className='buttonhover'>
            <ListItemText className='sidebarbutton' primary="Dashboard" />
          </ListItemButton>
        </ListItem></Link>
        <Link to="pyq" className='active' style={{ textDecoration: 'none' }}>
          <ListItem >
            <ListItemButton className='buttonhover'>
              <ListItemText primary="Previous Year Papers" />
            </ListItemButton>
          </ListItem></Link>

      </List>
      <Divider />
    </div>
  );


  const container = window !== undefined ? () => window().document.body : undefined;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [error, setError] = useState('');
  const { currentUser, logout } = useAuth();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  async function handleLogout() {
    try {
      setError('');
      localStorage.removeItem("token");
    } catch {
      setError('Failed to logout')
    }
  }
  const classes = useStyles();
  return (
    <div>
      <Box sx={{ display: 'flex', backdropFilter: '5px' }}>
        <CssBaseline />
        <AppBar component="nav" className='backdrop-appbar' sx={{ background: 'transparent', backdropFilter: '10px', padding: 0, borderColor: '#000' }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <div><a href="https://tosc.techkriti.org" target="_blank" rel="noreferrer"><img alt='' src={logo} className='logo' /></a></div>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            </Typography>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <div className='top-menu'>
                <Link style={{ textDecoration: 'none', color: '#fff' }} to='dashboard'>
                  <Button color="inherit">Dashboard &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>
                </Link>
                <Link style={{ textDecoration: 'none', color: '#fff' }} to='/pyq'>
                  <Button color="inherit">Previous Year Papers &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>
                </Link>
                {/* <Link style={{ textDecoration: 'none', color: '#fff' }} to='/certificate'>
                  <Button color="inherit">Certificate</Button>
                </Link> */}

              </div>
            </Typography>


            <Button
              aria-controls={open ? 'demo-positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true ' : undefined}
              onClick={handleClick} color="inherit"><AccountCircleIcon sx={{ color: '#E92D88B01E43' }} /></Button>
            <Menu
              className={classes.menu}
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              sx={{
                backdropFilter: "blur(6px)",
              }}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <div>


                <MenuItem onClick={handleLogout}>
                  <Grid spacing={2} container direction="row" alignItems="center">
                    <Grid item style={{ marginLeft: '10px', marginTop: '5px' }}>
                      <LogoutIcon sx={{ color: '#fff' }}/>
                    </Grid>
                    <Grid item>
                      <a href="https://tosc.techkriti.org" style={{ color: 'white', textDecoration: 'none' }}>Logout</a>
                    </Grid>
                  </Grid>
                </MenuItem>
                <MenuItem>
                  <Grid spacing={2} container direction="row" alignItems="center">
                    <Grid item style={{ marginLeft: '10px', marginTop: '5px' }}>
                      <AccountCircleIcon sx={{ color: '#fff' }} />
                    </Grid>
                    <Grid item>
                      <Link to = "/update-profile" style={{ color: 'white', textDecoration: 'none' }}>Update Profile</Link>
                    </Grid>
                  </Grid>
                </MenuItem>
              </div>
            </Menu>

          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            PaperProps={{
              sx: { backgroundColor: "#23232F" }
            }}

            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              backdropFilter: "blur(6px)",
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          {/* <Drawer
        PaperProps={{
          sx: { backgroundColor: "#23232F" }
                }}
          style={bgnavbar}
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open>
          {drawer}
        </Drawer> */}
        </Box>
      </Box>
    </div>
  );
}
Sidenavbar.propTypes = {
  window: PropTypes.func,
};
export default Sidenavbar;