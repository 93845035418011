import React, {useState} from 'react';





import axios from 'axios'
import { Container, Card} from 'react-bootstrap'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';

import {CSVLink} from 'react-csv';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useEffect } from 'react';



export default function ShowTask() {
const [alluser, setalluser] = useState([]);
const [url, setUrl] = useState('');
const handleSubmit = (event) => {
  event.preventDefault();
  console.log('Submitted URL:', url);


}
useEffect(() => {
    axios.get('https://toscbackend.onrender.com/api/get-all-users').then((response)=>{
        setalluser( response.data)
        console.log(response.data)
    }).catch((e)=>console.log(e))
}, [])

  return (
    <>
    <div style={{ textAlign: 'center', marginTop: '50px' }}>

    <h1 style={{ color: 'white' }}>Admin Page</h1>
    <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
      <label style={{ color: 'white' }}>
        School Registration :  
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          style={{ marginLeft: '10px', color: 'white', padding: '5px', width: '300px' }}
        />
      </label>
      <button type="submit" style={{ marginLeft: '10px', padding: '5px' }}>Submit</button>
    </form>

    <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
      <label style={{ color: 'white' }}>
        Payment Status Update :  
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          style={{ marginLeft: '10px', color: 'white', padding: '5px', width: '300px' }}
        />
      </label>
      <button type="submit" style={{ marginLeft: '10px', padding: '5px' }}>Submit</button>
    </form>

    <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
      <label style={{ color: 'white' }}>
        Enter URL :  
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          style={{ marginLeft: '10px', color: 'white', padding: '5px', width: '300px' }}
        />
      </label>
      <button type="submit" style={{ marginLeft: '10px', padding: '5px' }}>Submit</button>
    </form>

    <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
      <label style={{ color: 'white' }}>
        Enter URL :  
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          style={{ marginLeft: '10px', color: 'white', padding: '5px', width: '300px' }}
        />
      </label>
      <button type="submit" style={{ marginLeft: '10px', padding: '5px' }}>Submit</button>
    </form>

    

  </div>
    <div className='resp-user'>
    <div className='allUsers'>
  
        <Typography variant='h2' className='h2leader'>Users with payment not done</Typography>
       <button className='button-21'> <CSVLink data={alluser}><GetAppIcon></GetAppIcon> EXPORT</CSVLink></button>
        <Table>
        <TableHead>
        <TableRow>
          <TableCell sx={{color:'#fff'}}>Name</TableCell>
          <TableCell sx={{color:'#fff'}}>College</TableCell>
          <TableCell sx={{color:'#fff'}}>Phone</TableCell>
          <TableCell sx={{color:'#fff'}}>Email</TableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
    {/* {
        dataArray.map((user,index)=>LeaderBoardEntry(user,index))
    } */}
     {  
      
        alluser?.map((user,index)=>(
          
          <TableRow>
           <TableCell sx={{color:'#fff'}}>{user.name}</TableCell>
           <TableCell sx={{color:'#fff'}}>{user.school}</TableCell>
           <TableCell sx={{color:'#fff'}}>{user.phone}</TableCell>
           <TableCell sx={{color:'#fff'}}>{user.email}</TableCell>
          </TableRow>
        ))
    }
    <br/>
    </TableBody>
    </Table>
    
    </div>
    </div>
    </>
    
  )
  

}

// https://data.mongodb-api.com/app/data-zabod/endpoint/data/v1
// API Key: CQvgqYOXpk4dH9iEEHE7AezbX0WAOeQiQqn0c4siEeIguZ7eLOlW403J6tPfILDQ
