import React from 'react'
import {BrowserRouter as Router,Routes, Route} from 'react-router-dom'
import Userlayout from './Userlayout/Userlayout'
import Login from "./Login"
import Dashboard from './Dashboard'
import Previous from './Previous'
import ChangePass from './Changepass'
import { AuthProvider } from "../context/AuthContext";
import Signup from './Signup'
import Error from './Error'
import EmailPrivateRoute from './EmailPrivateRoute'
import ForgotPassword from './ForgotPassword';
import PrivateRoute from './PrivateRoute'
import Orders from './Admin'
import EmailPrivate from './EmailPrivate'
import Payment from './Payment'
import FormDetails from './FormDetails'
import Admin from './Admin'
import Poolresult from "./Poolresult";

function App(){ 
  return (
    <>
      <div className="w-100" style={{maxWidth : '100%'}}>
        <Router>
          <AuthProvider>
            <Routes>
            <Route  path="/" element={<Userlayout/>}>
              <Route exact path="/" element={<Dashboard />}/>
              <Route exact path="dashboard" element={<Dashboard />}/>
              <Route path="/pyq" element={<Previous />}/>
              <Route exact path="change-pass" element={<EmailPrivate><ChangePass/></EmailPrivate>}/>
              <Route exact path="/update-profile" element ={<FormDetails/>} />
              <Route exact path="payment" element={<Payment />}/>
              {/* <Route exact path="/certificate" element={<Certificate />} /> */}
            </Route>
            <Route exact path="/forgot-password" element ={<ForgotPassword/>} />
            <Route exact path = "/poolAresult" element= {<Poolresult pool="A" />}/>
            <Route exact path = "/poolBresult" element= {<Poolresult pool="B"/>}/>
            <Route exact path = "/poolCresult" element= {<Poolresult pool="C"/>}/>
            <Route exact path="/login" element={<Login/>}/>
            <Route exact path="/signup" element={<Signup/>}/>
            <Route path="*" element={<Error message="No matching Route Present" solution="Check whether the URL is correct or not."/>} />
  
            <Route exact path="/admin=E3B25DB2A2749648E4F34D19A57DE" element={<Admin/>}/>
          </Routes>
          </AuthProvider>
        </Router>
      </div>
    </>
    

  );
}

export default App;