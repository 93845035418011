import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { Form, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import logo from "./Userlayout/TOSC_white.png";
import { alpha, styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { yellow } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { SettingsRemoteOutlined, WindowRounded } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const CssTextField = styled(TextField)({
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "#f0f0f0",
    color: "#000",
  },
  "& .MuiSvgIcon-root": {
    color: "#fff !important",
  },
  "& label.Mui-focused": {
    color: "white",
    input: {
      color: "white",
    },
  },
  "& label.Mui": {
    color: "white",
    input: {
      color: "white",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
    color: "white",
    input: {
      color: "white",
    },
  },
  "& .MuiFormLabel-root": {
    borderBottomColor: "white",
    color: "white",
    input: {
      color: "white",
    },
  },
  "& .MuiOutlinedInput-root": {
    input: {
      color: "white",
    },
    "& fieldset": {
      borderColor: "grey",
      color: "white",
    },
    "&:hover fieldset": {
      borderColor: "grey",
      color: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
      color: "white",
      input: {
        color: "white",
      },
    },
    "&.MuiInputBase-input fieldset": {
      borderColor: "white",
      color: "white",
      input: {
        color: "white",
      },
    },
  },
});

export default function FormDetails() {
  const [user, setUser] = useState({});
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchUserData(token);
    } else {
      navigate('/login');
    }
  }, []);

  const fetchUserData = async (token) => {
    try {
      const response = await fetch("https://toscbackend.onrender.com/api/getuser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      });
      if (response.ok) {
        const json = await response.json();
        setUser(json);
      } else {
        setError("Error in fetching data...");
      }
    } catch (err) {
      console.log(err);
      setError("Error in fetching data...");
    } finally {
      setLoadingUser(false);
    }
  };

  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const nameRef = useRef();
  const parentNameRef = useRef();
  const schoolNameRef = useRef();
  const schoolCityRef = useRef();
  const schoolAddressRef = useRef();
  const phoneRef = useRef();
  const dobRef = useRef();
  const [grade, setGrade] = useState("");
  const [gender, setGender] = useState("");
    const [openMessage, setOpenMessage] = useState(true)

  //   const controlProps = (item) => ({
  //   checked: selectedValue === item,
  //   onChange: handleChange,
  //   value: item,
  //   name: 'color-radio-button-demo',
  //   inputProps: { 'aria-label': item },
  // });
  async function handleSubmit(e) {
    e.preventDefault();
    setLoadingForm(true);
    let pool;
    if (grade === "6th" || grade === "7th" || grade === "8th") pool = "A";
    else if (grade === "9th" || grade === "10th") pool = "B";
    else if (grade === "11th" || grade === "12th") pool = "C";
    const formData = {
      email: user?.email,
      name: nameRef.current.value,
      parentName: parentNameRef.current.value,
      schoolName: schoolNameRef.current.value,
      schoolCity: schoolCityRef.current.value,
      schoolAddress: schoolAddressRef.current.value,
      phone: phoneRef.current.value,
      dob: dobRef.current.value,
      grade: grade,
      pool: pool,
      gender: gender,
      isupdateprofile: true,
    };

    try {
      let response = await fetch("https://toscbackend.onrender.com/api/update_details", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(formData),
      });
      response = await response.json();
      if (response.ok) {
        setMessage("Details Updated Successfully.");
        setOpen(true);
        setLoadingUser(true);
        fetchUserData(localStorage.getItem("token"));

        setTimeout(() => {
          navigate('/dashboard');
        }, 2000);
      } else {
        setError("Failed to update details.");
      }
    } catch (error) {
      console.error(error);
      setError("An error occurred. Please try again later.");
    }

    setLoadingForm(false);
  }

  if (loadingUser) {
    return <CircularProgress />;
  }
      const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleCloseMessage = ()=> setOpenMessage(false)
  function onChangeGrade(e) {
    setGrade(e.target.value);
  }
  function onChangeGender(e) {
    setGender(e.target.value);
  }

  return (
    <>
     <div className='form-gl'>
     <a href = "https://tosc.techkriti.org" target="_blank" rel="noreferrer"> <img src={logo} className="tosc_img tosc_img_signup" alt="tosc logo" /></a>
    {loading?<Box sx={{ display: 'block',textAlign:'center',marginTop:'100px' }}><CircularProgress
        /></Box>:
      <>
        <h1 className="text-center">Register</h1>
        {error &&
            <Modal open={open} onClose={handleClose}>
              <Box sx={style}>
                <center><ErrorIcon/> {error}</center>
              </Box>
            </Modal>}
        {message &&
          <Modal open={openMessage} onClose={handleCloseMessage}>
              <Box sx={style}>
                <center><CheckCircleOutlineIcon/> {message}</center>
              </Box>
            </Modal>
        }
    
        {!(user?.isupdateprofile) && <div style={{textAlign:'center',marginBottom:'20px'}}>Enter your details to complete your registration and unlock your dashboard.</div>}
        {(user?.isupdateprofile) && <div style={{textAlign:'center',marginBottom:'20px'}}>Update your Profile</div>}
        <br/>
      <Form onSubmit={handleSubmit}>
        <Form.Group
          id="name"
          // className="signup-input resp-signup-input text-white"
          className="login-input"
          
        >
          Name* &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
          
          <CssTextField
            className="resp-signup-input"
            placeholder="Your Name Here"
            id=""
            size="small"
            inputRef={nameRef}
            value={user?.name}
            sx={{ paddingLeft: "10px", paddingTop: "-10px", width: "50%" }}
            required
            disabled
          />
        </Form.Group>
        <Form.Group id="email" className="login-input">
          Email* &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          

          <CssTextField
            id=""
            className="resp-signup-input"
            size="small"
            disabled
            value={user?.email}
            sx={{ paddingLeft: "13px", paddingTop: "-10px", width: "50%" }}
            required
          />
        </Form.Group>
        <Form.Group id="parentname" className="login-input">
          Parent's/Guardian's Name*
          <CssTextField
            className="resp-signup-input"
            placeholder="Parent's/Guardian's Name"
            id=""
            size="small"
            defaultValue={user?.parentName}
            inputRef={parentNameRef}
            sx={{ paddingLeft: "10px", paddingTop: "-10px", width: "50%" }}
            required
          />
        </Form.Group>
        <Form.Group id="dob" className="login-input">
          <Form.Label>
            Date of
            Birth*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Form.Label>
          <CssTextField
            className="resp-signup-input"
            inputRef={dobRef}
            id=""
            type="date"
            size="small"
            defaultValue={user?.dob}
            sx={{ paddingLeft: "10px", paddingTop: "-10px", width: "50%" }}
            required
          />
        </Form.Group>
        <Form.Group id="phone" className="login-input">
          <Form.Label>
            Mobile Number*
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Form.Label>
          <CssTextField
            className="resp-signup-input"
            inputRef={phoneRef}
            id=""
            placeholder="Mobile Number"
            defaultValue={user?.phone}
            type="number"
            size="small"
            sx={{ paddingLeft: "12px", paddingTop: "-10px", width: "50.5%" }}
            required
          />
        </Form.Group>
        {/* <Form.Group id="whatsAppNumber" className="login-input">
            <Form.Label> WhatsApp Number* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Form.Label>
            <CssTextField className='resp-signup-input'  inputRef={whatsAppRef} id="" placeholder ="WhatsApp Number" type="number" size="small" sx={{paddingLeft:'10px', paddingTop:'-10px', width:'50%'}} required />
          {/* Note that the Whatsapp Number can be similar to the Mobile   */}
        {/* </Form.Group> */}
        <h2>School Details</h2> <br />
        <Form.Group id="schoolName" className="login-input">
          <Form.Label>School/Coaching Name* &nbsp;&nbsp;&nbsp;</Form.Label>
          <CssTextField
            className="resp-signup-input"
            placeholder="School Name"
            id=""
            size="small"
            defaultValue={user?.schoolName}
            inputRef={schoolNameRef}
            sx={{ paddingLeft: "10px", paddingTop: "-10px", width: "50%" }}
            required
          />
        </Form.Group>
        <Form.Group id="schoolCity" className="login-input">
          <Form.Label>
            School/Coaching City* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Form.Label>
          <CssTextField
            className="resp-signup-input"
            placeholder="School City"
            id=""
            size="small"
            defaultValue={user?.schoolCity}
            inputRef={schoolCityRef}
            sx={{ paddingLeft: "10px", paddingTop: "-10px", width: "50%" }}
            required
          />
        </Form.Group>
        <Form.Group id="schoolAddress" className="login-input">
          <Form.Label>School/Coaching Address* </Form.Label>
          <CssTextField
            className="resp-signup-input"
            placeholder="School Address"
            id=""
            size="small"
            defaultValue={user?.schoolAddress}
            inputRef={schoolAddressRef}
            sx={{ paddingLeft: "10px", paddingTop: "-10px", width: "50%" }}
            required
          />
        </Form.Group>
        <Grid container>
          <Grid item xs={12}>
            <h3>Pool A </h3>
            <Stack spacing={3} direction="row">
              <FormControlLabel
                className="sigfn-radio-input"
                control={
                  <Radio
                    required={true}
                    sx={{
                      color: yellow[800],
                      "&.Mui-checked": {
                        color: yellow[600],
                      },
                    }}
                  />
                }
                checked={grade === "6th"}
                onChange={onChangeGrade}
                value="6th"
                name="grade"
                label="6th"
                inline
                sx={{ paddingLeft: "10px", paddingTop: "-10px", width: "10%" }}
                required
              />
              <FormControlLabel
                className="sifgn-radio-input"
                control={
                  <Radio
                    required={true}
                    sx={{
                      color: yellow[800],
                      "&.Mui-checked": {
                        color: yellow[600],
                      },
                    }}
                  />
                }
                checked={grade === "7th"}
                onChange={onChangeGrade}
                value="7th"
                name="grade"
                label="7th"
                inline
                sx={{ paddingLeft: "10px", paddingTop: "-10px", width: "10%" }}
                required
              />
              <FormControlLabel
                className="sifgn-radio-input"
                control={
                  <Radio
                    required={true}
                    sx={{
                      color: yellow[800],
                      "&.Mui-checked": {
                        color: yellow[600],
                      },
                    }}
                  />
                }
                checked={grade === "8th"}
                onChange={onChangeGrade}
                value="8th"
                name="grade"
                label="8th"
                inline
                sx={{ paddingLeft: "10px", paddingTop: "-10px", width: "10%" }}
                required
              />
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <h3>Pool B</h3>
            <Stack direction="row" spacing={3}>
              <FormControlLabel
                className="sifgn-radio-input"
                control={
                  <Radio
                    required={true}
                    sx={{
                      color: yellow[800],
                      "&.Mui-checked": {
                        color: yellow[600],
                      },
                    }}
                  />
                }
                checked={grade === "9th"}
                onChange={onChangeGrade}
                value="9th"
                name="grade"
                label="9th"
                inline
                sx={{ paddingLeft: "10px", paddingTop: "-10px", width: "10%" }}
                required
              />
              <FormControlLabel
                className="sigfn-radio-input"
                control={
                  <Radio
                    required={true}
                    sx={{
                      color: yellow[800],
                      "&.Mui-checked": {
                        color: yellow[600],
                      },
                    }}
                  />
                }
                checked={grade === "10th"}
                onChange={onChangeGrade}
                value="10th"
                name="grade"
                label="10th"
                inline
                sx={{ paddingLeft: "10px", paddingTop: "-10px", width: "10%" }}
                required
              />
            </Stack>
          </Grid>
          <Grid xs={12}>
            <h3>Pool C</h3>
            <Stack direction="row" spacing={3}>
              <FormControlLabel
                className="sigfn-radio-input"
                control={
                  <Radio
                    required={true}
                    sx={{
                      color: yellow[800],
                      "&.Mui-checked": {
                        color: yellow[600],
                      },
                    }}
                  />
                }
                checked={grade === "11th"}
                onChange={onChangeGrade}
                value="11th"
                name="grade"
                label="11th"
                inline
                sx={{ paddingLeft: "10px", paddingTop: "-10px", width: "10%" }}
                required
              />
              <FormControlLabel
                className="sifgn-radio-input"
                control={
                  <Radio
                    required={true}
                    sx={{
                      color: yellow[800],
                      "&.Mui-checked": {
                        color: yellow[600],
                      },
                    }}
                  />
                }
                checked={grade === "12th"}
                onChange={onChangeGrade}
                value="12th"
                name="grade"
                label="12th"
                inline
                sx={{ paddingLeft: "10px", paddingTop: "-10px", width: "10%" }}
                required
              />
            </Stack>
          </Grid>
        </Grid>
        <h3>Gender</h3>
        <FormControlLabel
          className="sign-radfio-input"
          control={
            <Radio
              required={true}
              sx={{
                color: yellow[800],
                "&.Mui-checked": {
                  color: yellow[600],
                },
              }}
            />
          }
          checked={gender === "male"}
          onChange={onChangeGender}
          name="gender"
          value="male"
          label="Male"
          inline
          required
        />
        <FormControlLabel
          className="sign-radifo-input"
          control={
            <Radio
              required={true}
              sx={{
                color: yellow[800],
                "&.Mui-checked": {
                  color: yellow[600],
                },
              }}
            />
          }
          checked={gender === "female"}
          onChange={onChangeGender}
          name="gender"
          value="female"
          label="Female"
          inline
          required
        />
        <br />
        <div className="text-center">
          <Button disabled={loading} className="w-100 button-5" type="submit">
            Submit
          </Button>
        </div>
      </Form>
      </>
}
    </div>
    </>
  );

}
