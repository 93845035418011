import React from 'react';
import {Outlet} from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Headers from './Header'
import Sidenavbar from './Sidenavbar';
import logo from './TOSC_white.png';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'right',
  height: '8vh',
  background:'../../../public/bg.png',
  color: theme.palette.text.secondary,
}));

function Userlayout(){
  return(
    <div>
      <Box>
        <Grid container spacing={2}>
        
          <Grid item xs sx={{boxShadow:0, border:0}}>
            <Sidenavbar />
          </Grid>
          <Grid item xs={11}>
          <div className='resp'><Outlet /></div>
        </Grid>
        </Grid>
      </Box>
    </div>
  )
}
export default Userlayout;