const top100StudentsPoolC = [
  {
    "": "",
    "name": "Garv Gupta",
    "email": "garvguptaa2611@gmail.com",
    "phone": "6266148926"
  },
  {
    "": "",
    "name": "Ishaan Chugh",
    "email": "ishaanjichugh@gmail.com",
    "phone": "6280245685"
  },
  {
    "": "",
    "name": "Kshitij Saraogi",
    "email": "kshitijsaraogi08@gmail.com",
    "phone": "6290000300"
  },
  {
    "": "",
    "name": "Saksham Chaubey",
    "email": "mrsakshamamerican@gmail.com",
    "phone": "6387846511"
  },
  {
    "": "",
    "name": "Gaurika Mahi",
    "email": "gaurikamahishs@gmail.com",
    "phone": "7004484791"
  },
  {
    "": "",
    "name": "Omm Swarup",
    "email": "ommswarup2005@gmail.com",
    "phone": "7008426373"
  },
  {
    "": "",
    "name": "Kuldeep Meena",
    "email": "kuldeepmeena705595@gmail.com",
    "phone": "7055954373"
  },
  {
    "": "",
    "name": "Aanya Saxena",
    "email": "aasaxena2006@gmail.com",
    "phone": "7080898969"
  },
  {
    "": "",
    "name": "Anunit Rampurkar",
    "email": "anunit3106.ar@gmail.com",
    "phone": "7083211446"
  },
  {
    "": "",
    "name": "Aryan Sharma",
    "email": "aryan4hills@gmail.com",
    "phone": "7201815474"
  },
  {
    "": "",
    "name": "Kritika Kumari",
    "email": "kritika1606oc@gmail.com",
    "phone": "7275281985"
  },
  {
    "": "",
    "name": "Devasya Misra",
    "email": "devasyamisra@gmail.com",
    "phone": "7292072857"
  },
  {
    "": "",
    "name": "Ramawtar Nehra",
    "email": "dheryanshunehra@outlook.com",
    "phone": "7340222527"
  },
  {
    "": "",
    "name": "Satwik Gupta",
    "email": "satwikgupta24680@gmail.com",
    "phone": "7783000645"
  },
  {
    "": "",
    "name": "Kartik Vijaywergiya",
    "email": "kartikvijay.mp@gmail.com",
    "phone": "7827834078"
  },
  {
    "": "",
    "name": "Hitaine Bindal",
    "email": "hitainebindal@gmail.com",
    "phone": "7901748262"
  },
  {
    "": "",
    "name": "Chirjot  Singh",
    "email": "chirjotsinghdung@gmail.com",
    "phone": "7988025398"
  },
  {
    "": "",
    "name": "Tarun Kumar",
    "email": "gaursudha1234@gmail.com",
    "phone": "7991205014"
  },
  {
    "": "",
    "name": "Maaz Ahmad",
    "email": "15949@jaipuria.com",
    "phone": "8052521052"
  },
  {
    "": "",
    "name": "Aniket kumar",
    "email": "aniketkumar20208@gmail.com",
    "phone": "8092308894"
  },
  {
    "": "",
    "name": "Amelea Asmijan",
    "email": "asmiam@gmail.com",
    "phone": "8281634215"
  },
  {
    "": "",
    "name": "shreyan",
    "email": "Shreyangupta4598@gmail.com",
    "phone": "8318931974"
  },
  {
    "": "",
    "name": "BEVAN SAM",
    "email": "bevanbiju2007@gmail.com",
    "phone": "8349065810"
  },
  {
    "": "",
    "name": "Dakshesh Shrivastav",
    "email": "devendrakriti@rediffmail.com",
    "phone": "8481971514"
  },
  {
    "": "",
    "name": "Subodh Patil",
    "email": "patilsubodh630@gmail.com",
    "phone": "8625019473"
  },
  {
    "": "",
    "name": "Praney Bansal",
    "email": "Praneybansal@gmail.com",
    "phone": "8,694,000,049"
  },
  {
    "": "",
    "name": "Mohafiz",
    "email": "ansarimdmohafiz6@gmail.com",
    "phone": "8755882175"
  },
  {
    "": "",
    "name": "Suraj Rai",
    "email": "surajdanger1@gmail.com",
    "phone": "8765010505"
  },
  {
    "": "",
    "name": "Jatin dahiya",
    "email": "jatindahiya5117@gmail.com",
    "phone": "8882503517"
  },
  {
    "": "",
    "name": "Vineet Yadav",
    "email": "vineetbhai1901@gmail.com",
    "phone": "8930704182"
  },
  {
    "": "",
    "name": "Gunjan Likhar",
    "email": "gunjanlikhar85@gmail.com",
    "phone": "8962747009"
  },
  {
    "": "",
    "name": "Rashida Bazmi",
    "email": "asgarhusain72@gmail.com",
    "phone": "8989887252"
  },
  {
    "": "",
    "name": "ANJISHNU CHATTOPADHYAY",
    "email": "chattopadhyayami72@gmail.com",
    "phone": "9007670447"
  },
  {
    "": "",
    "name": "Piyush Kakde",
    "email": "theroadnottaken2357@gmail.com",
    "phone": "9028816037"
  },
  {
    "": "",
    "name": "Abhinav Agarwal",
    "email": "abhibhaiagarwal123@gmail.com",
    "phone": "9105555690"
  },
  {
    "": "",
    "name": "Raj gupta",
    "email": "guptarai9113378591@gmail.com",
    "phone": "9113378591"
  },
  {
    "": "",
    "name": "Prakhar jain",
    "email": "pjain310507@gmail.com",
    "phone": "9131177138"
  },
  {
    "": "",
    "name": "Manikant kumar",
    "email": "manikantkumar.shs@gmail.com",
    "phone": "9135501119"
  },
  {
    "": "",
    "name": "Anushka Sharma",
    "email": "anushkaavinashsharma@gmail.com",
    "phone": "9174488088"
  },
  {
    "": "",
    "name": "SARANSH BACHCHAN",
    "email": "SaranshBachchann13555@gmail.com",
    "phone": "9253303333"
  },
  {
    "": "",
    "name": "Ipshita Singh",
    "email": "satyendra2953@gmail.com",
    "phone": "9412768353"
  },
  {
    "": "",
    "name": "ASHWIN SHARMA",
    "email": "aashwinsharma07@gmail.com",
    "phone": "9413346656"
  },
  {
    "": "",
    "name": "Dhyanvi Patel",
    "email": "dhyanvi1222@gmail.com",
    "phone": "9429429496"
  },
  {
    "": "",
    "name": "Akash singh",
    "email": "akashsingh310484@gmail.com",
    "phone": "9452286610"
  },
  {
    "": "",
    "name": "HIMISHA JAIN",
    "email": "himisha1806@gmail.com",
    "phone": "9479533661"
  },
  {
    "": "",
    "name": "Pranaya Neela",
    "email": "Pranaya.cvv@gmail.com",
    "phone": "9498412712"
  },
  {
    "": "",
    "name": "Pranav Kumar",
    "email": "MEISPKVERMA@GMAIL.COM",
    "phone": "9522601988"
  },
  {
    "": "",
    "name": "Vipul Mittal",
    "email": "vipulmittal803@gmail.com",
    "phone": "9528143384"
  },
  {
    "": "",
    "name": "Aditya Punjani",
    "email": "punjanianruadha@gmail.com",
    "phone": "9555455386"
  },
  {
    "": "",
    "name": "vedanti Sunil",
    "email": "sunil6048@gmail.com",
    "phone": "9579349303"
  },
  {
    "": "",
    "name": "Arpit Pandey",
    "email": "pandeyarpit266@gmail.com",
    "phone": "9648071600"
  },
  {
    "": "",
    "name": "Hansika",
    "email": "hansikavedhere@gmail.com",
    "phone": "9701394943"
  },
  {
    "": "",
    "name": "Manav Goel",
    "email": "goel.manav07@gmail.com",
    "phone": "9718712004"
  },
  {
    "": "",
    "name": "Anshul Shah",
    "email": "shah_vipul@hotmail.com",
    "phone": "9826024005"
  },
  {
    "": "",
    "name": "Parthiba Naskar",
    "email": "parthibanaskar2937@gmail.com",
    "phone": "9831617088"
  },
  {
    "": "",
    "name": "Kirit Gupta",
    "email": "kirit.gupta2006@gmail.com",
    "phone": "9868063721"
  },
  {
    "": "",
    "name": "unnabh Bhalla",
    "email": "discordpurpose0001@gmail.com",
    "phone": "9899815557"
  },
  {
    "": "",
    "name": "Sourav Kumar",
    "email": "kumarsourav5960@gmail.com",
    "phone": "9910978859"
  },
  {
    "": "",
    "name": "Rudra Patel",
    "email": "rudrapatel222444@gmail.com",
    "phone": "9925928895"
  },
  {
    "": "",
    "name": "Rohan Gupta",
    "email": "rohan202220230616@cambridgecourtworldschool.org",
    "phone": "9928539631"
  },
  {
    "": "",
    "name": "SP Singh",
    "email": "sp112608@gmail.com",
    "phone": "9962040333"
  },
  {
    "": "",
    "name": "Yash Want",
    "email": "sundipksingh@gmail.com",
    "phone": "9968320785"
  },
  {
    "": "",
    "name": "Vidhi Maiyani",
    "email": "vpmaiyani22@gmail.com",
    "phone": "9978644670"
  },
  {
    "": "",
    "name": "Shreeya",
    "email": "shreeya.allen@gmail.com",
    "phone": "9999297287"
  },
  {
    "": "",
    "name": "Tulikka Garg",
    "email": "rcg001@gmail.com",
    "phone": "9450583900"
  },
  {
    "": "",
    "name": "Jiya Vinayak",
    "email": "jiyavinayak12@gmail.com",
    "phone": "+918109506198"
  },
  {
    "": "",
    "name": "anushka trivedi",
    "email": "atrivedi.5467@gmail.com",
    "phone": "7879582895"
  },
  {
    "": "",
    "name": "Nikesh Balakumar",
    "email": "nikesh2507@gmail.com",
    "phone": "6364062412"
  },
  {
    "": "",
    "name": "Vaishnavi Jai",
    "email": "123jaiswalpawan@gmail.com",
    "phone": "7521854114"
  },
  {
    "": "",
    "name": "Riddhima Srivastava",
    "email": "srivastavariddhima464@gmail.com",
    "phone": "7985987636"
  },
  {
    "": "",
    "name": "Rahul Dewangan",
    "email": "rahuldewanganig@gmail.com",
    "phone": "7999520459"
  },
  {
    "": "",
    "name": "Nihal singh",
    "email": "10262@jaipuria.com",
    "phone": "8318455825"
  },
  {
    "": "",
    "name": "vaibhav",
    "email": "vaibhavkumbhar8686@gmail.com",
    "phone": "8329704263"
  },
  {
    "": "",
    "name": "Stuti Singh",
    "email": "stuti05052007@gmail.com",
    "phone": "8619691183"
  },
  {
    "": "",
    "name": "Adwait Sharma",
    "email": "manvisharma023@gmail.com",
    "phone": "8650478584"
  },
  {
    "": "",
    "name": "maheshjaat",
    "email": "mkjaat307@gmail.com",
    "phone": "9057699016"
  },
  {
    "": "",
    "name": "Anusha Kumar",
    "email": "p-1295-12@dpspatna.com",
    "phone": "9122404777"
  },
  {
    "": "",
    "name": "abhigyan singh",
    "email": "abhi890here@gmail.com",
    "phone": "9140171082"
  },
  {
    "": "",
    "name": "iram naaz",
    "email": "iramabbasi785@gmail.com",
    "phone": "9528786000"
  },
  {
    "": "",
    "name": "Aayush",
    "email": "opgaming198211@gmail.com",
    "phone": "9650920687"
  },
  {
    "": "",
    "name": "Sayan Harjai",
    "email": "sayanharjai123@gmail.com",
    "phone": "9971718913"
  },
  {
    "": "",
    "name": "bhavya sanghvi",
    "email": "bhavya.sanghvi17@gmail.com",
    "phone": "9993110702"
  },
  {
    "": "",
    "name": "Afreen Hossain",
    "email": "afreenhossain0000@gmail.com",
    "phone": "+91 9480297594"
  },
  {
    "": "",
    "name": "Sayana",
    "email": "yadavsarita92920@gmail.com",
    "phone": "9588377800"
  },
  {
    "": "",
    "name": "RIYA NAGAR",
    "email": "info@klischool.com",
    "phone": "8979595500"
  },
  {
    "": "",
    "name": "AYRA KATHURIA",
    "email": "info@klischool.com",
    "phone": "8979595500"
  }
]
  
  export default top100StudentsPoolC;