import React,{useRef} from 'react'
import { useState,useEffect } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import './styles.css'
import { styled } from '@mui/material/styles';
import { TextField } from "@mui/material";
import { Button} from "react-bootstrap"
import logo from './Userlayout/TOSC_white.png';
const CssTextField = styled(TextField)({
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#f0f0f0',
      color:'#000'
    },
    '& label.Mui-focused': {
      
      color: 'white',
      input:{
        color:"white"
    }
    },
    '& label.Mui': {
        color: 'white',
        input:{
          color:"white"
      }
      },
    '& .MuiInput-underline:after': {
      
      borderBottomColor: 'white',
      color:'white',
      input:{
        color:"white"
    }
    },
    '& .MuiFormLabel-root': {
      
        borderBottomColor: 'white',
        color:'grey',
        input:{
          color:"white"
      }
      },
    '& .MuiOutlinedInput-root': {
      
        input:{
          
            color:"white"
        },
      '& fieldset': {
        borderColor: 'grey',
        color:'white',
        
      },
      '&:hover fieldset': {
        borderColor: 'grey',
        color:'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
        color:'white',
        input:{
          
            color:"white"
        },
      },
      '&.MuiInputBase-input fieldset': {
        
        borderColor: 'white',
        color:'white',
        input:{
          
            color:"white"
        }
      },
    },
  });
export default function PrivateRoute({children}) {
    const [credentials,setCredentials] = useState()

    useEffect(()=>{
      axios.get(`${"https://toscbackend.onrender.com/api"}/get-admin-details`).then((response)=>{
        // console.log(response.data)
          setCredentials(response.data)
      }).catch((err)=>alert("error in fetching data"))
    },[])
    const userRef = useRef();
    const passwordRef = useRef();
    const [currentUser, setCurrentUser] = useState(false);
    function handleSubmit(e){
        e.preventDefault();
        if(userRef.current.value===credentials.username && passwordRef.current.value===credentials.password) setCurrentUser(true)
        else alert("Incorrect credentials.")   
    }
  return (
    !currentUser?
    <div className="login-gl">
      <img src={logo} className="tosc_img tosc_img_login" alt="logo" />
        <Form onSubmit={handleSubmit} style={{textAlign:'center',marginTop:'10vh'}}>
          <h1>Admin Login</h1>
            <Form.Group id="email" className="login-input">
              <Form.Label className="labelF">Username</Form.Label>
                   <CssTextField label="Username" id="" size="small" inputRef={userRef} sx={{paddingLeft:'10px', paddingTop:'-10px'}} />
            </Form.Group>
            <Form.Group id="password" className="login-input">
              <Form.Label>Password </Form.Label>
              <CssTextField label="Password" type="password" id="custom-outlined-input" size="small" inputRef={passwordRef} sx={{paddingLeft:'10px'}} />
            </Form.Group>
            <Button onClick={handleSubmit} className="w-100 button-5" type="submit">
              Log In
            </Button>            
          </Form>
    </div>:children
    
  )
}
