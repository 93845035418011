import React from 'react'
import { Link } from 'react-router-dom'
import logo from './Userlayout/TOSC_white.png';

export default function Error(props) {
  return (
    <div className='dashboard-gl error-gl' style={{height:'350px'}}>
    <a href = "https://tosc.techkriti.org"> <img src={logo} className="tosc_img tosc_img_signup" alt="tosc logo" /></a>
      <h1>404! Not Found</h1>
      <div style={{marginTop:'10px'}}><strong>Error Type: </strong><font color="gray">{props.message}</font></div>
      <div style={{marginTop:'10px'}}><strong>Possible Solution: </strong><font color="gray">{props.solution}</font></div>
      <Link to="/login" class="button-5" style={{marginTop:'20px'}}>Go back to Login</Link>
    </div>
  )
}