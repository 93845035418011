import {Divider, Grid, Typography } from "@mui/material"
import Box from '@mui/material/Box';
import React from "react"
import TextField from '@mui/material/TextField';



export default function ChangePass() {
  return (
    <div className="pass-gl">
    <Box>
    <div className="dashboard-card">
     
      <Grid container>
        <Grid item xs={12}>
        <div className="dashboard-card"><Typography variant="h2" sx={{color:'#fff', display:'inline-block'}}>Password&nbsp;</Typography><Typography variant="h2" sx={{color:'#df2146', display:'inline-block'}}>Change</Typography>
        <Divider
        light={true}
        />
      </div>
        </Grid>
        <Grid item xs={12}>
        <Typography align="center" sx={{align:'center'}}></Typography>
        <div className="pass-change"> 
        <div style={{marginBottom:'20px'}}>Old Password 
        <TextField 
            sx={{height:"10px", marginBottom:'10px' ,
            "& .MuiInputBase-root": {
            height: 30,
            
        }}}
            type="password"
            variant="filled"
        /></div>
        <div style={{marginBottom:'20px'}}>Old APassword 
        <TextField 
            sx={{height:"10px", marginBottom:'10px' ,
            "& .MuiInputBase-root": {
            height: 30,
            
        }}}
            type="password"
            variant="filled"
        /></div>
        <div style={{marginBottom:'20px'}}>Old Password 
        <TextField 
            sx={{height:"10px", marginBottom:'10px' ,
            "& .MuiInputBase-root": {
            height: 30,
            
        }}}
            type="password"
            variant="filled"
        /></div>

        
        </div>
        </Grid>
      </Grid>
      </div>
     </Box>
    </div>
  )
}