import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Card } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import { Link } from "react-router-dom";
import axios from "axios";
import logo from "./Userlayout/TOSC_white.png";
import Chip from "@mui/material/Chip";
import { alpha, styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CssTextField = styled(TextField)({
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "#f0f0f0",
    color: "#000",
  },
  "& label.Mui-focused": {
    color: "white",
    input: {
      color: "white",
    },
  },
  "& label.Mui": {
    color: "white",
    input: {
      color: "white",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
    color: "white",
    input: {
      color: "white",
    },
  },
  "& .MuiFormLabel-root": {
    borderBottomColor: "white",
    color: "grey",
    input: {
      color: "white",
    },
  },
  "& .MuiOutlinedInput-root": {
    input: {
      color: "white",
    },
    "& fieldset": {
      borderColor: "grey",
      color: "white",
    },
    "&:hover fieldset": {
      borderColor: "grey",
      color: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
      color: "white",
      input: {
        color: "white",
      },
    },
    "&.MuiInputBase-input fieldset": {
      borderColor: "white",
      color: "white",
      input: {
        color: "white",
      },
    },
  },
});

export default function Signup() {
  const [all_orders, setAllOrders] = useState([]);
  const navigate = useNavigate();
  const [openMessage, setOpenMessage] = React.useState(true);
  const [open, setOpen] = React.useState(true);

  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const phoneref = useRef();
  const nameref = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { currentUser, login } = useAuth();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  function handleSubmit(e) {
    setError("");
    setLoading(true);
    e.preventDefault();
    if (passwordRef.current.value.length < 6) {
      setOpen(true);
      setLoading(false);
      setError("Password too short, atleast 6 characters are required.");
    } else if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      setOpen(true);
      setLoading(false);
      setError("Passwords do not match");
    } else {
      setError("");
      setLoading(true);
      setOpenMessage(false);
      const email = emailRef.current.value;
      const password = passwordRef.current.value;
      const phone = phoneref.current.value;
      const name = nameref.current.value;
      fetch (`${"https://toscbackend.onrender.com/api"}/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, password, phone }),
      }).then((response) => response.json()).then((json) => {
          fetch (`${"https://toscbackend.onrender.com/api"}/signin`, {
        method : "POST",
        body : JSON.stringify({email: email,
          password: password,}) ,
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((response) => response.json()).then((json) => {
        console.log("working fire");
        console.log(json.token)
        localStorage.setItem("token", json.token);
        navigate("/dashboard");
      })
      .catch((err) => {
        // currentUser.delete();
        setOpen(true);
        setLoading(false);
        console.log("Yaaha aa");
        setError("Failed to update data in the database.");
      });
        })
        .catch((err) => {
          setOpen(true);
          setLoading(false);
          console.log(err);
          setError(`err`);
        });
    }
  }
  return (
    <div>
      <div className="signup-gl" style={{ height: "500px" }}>
        <a href="https://tosc.techkriti.org">
          {" "}
          <img
            src={logo}
            className="tosc_img tosc_img_signup"
            alt="tosc logo"
          />
        </a>
        {loading ? (
          <Box
            sx={{ display: "block", textAlign: "center", marginTop: "100px" }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <h2 className="text-center mb-4">Sign Up</h2>
            {error && (
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {error}
                </Alert>
              </Snackbar>
            )}
            {message && (
              <Snackbar
                open={openMessage}
                autoHideDuration={60000}
                onClose={handleCloseMessage}
              >
                <Alert
                  onClose={handleCloseMessage}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Successfully Registered. Go to login page.
                </Alert>
              </Snackbar>
            )}
            <div style={{ textAlign: "center" }}>
              <Form onSubmit={handleSubmit}>
                <Form.Group id="email" className="login-input">
                  <Form.Label>
                    Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Form.Label>
                  <CssTextField
                    label="Email"
                    id="custom--input"
                    type=""
                    size="small"
                    inputRef={emailRef}
                    sx={{ paddingLeft: "10px", paddingTop: "-10px" }}
                    required
                  />
                </Form.Group>
                <Form.Group id="name" className="login-input">
                  <Form.Label>
                    Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Form.Label>
                  <CssTextField
                    label="Name"
                    id="custom--input"
                    type=""
                    size="small"
                    inputRef={nameref}
                    sx={{ paddingLeft: "10px", paddingTop: "-10px" }}
                    required
                  />
                </Form.Group>
                <Form.Group id="phone" className="login-input">
                  <Form.Label>
                    Contact Number&nbsp;&nbsp;&nbsp;
                  </Form.Label>
                  <CssTextField
                    label="Phone"
                    id="custom--input"
                    type=""
                    size="small"
                    inputRef={phoneref}
                    sx={{ paddingLeft: "10px", paddingTop: "-10px" }}
                    required
                  />
                </Form.Group>
                <Form.Group id="password" className="login-input">
                  <Form.Label>
                    Password&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Form.Label>
                  <CssTextField
                    label="Password"
                    type="password"
                    id="custom-outlined-input"
                    size="small"
                    inputRef={passwordRef}
                    sx={{ paddingLeft: "10px" }}
                    required
                  />
                </Form.Group>
                <Form.Group id="password-confirm" className="login-input">
                  <Form.Label>Confirm Password</Form.Label>
                  <CssTextField
                    label="Password"
                    type="password"
                    id="custom-outline"
                    size="small"
                    inputRef={passwordConfirmRef}
                    sx={{ paddingLeft: "10px" }}
                    required
                  />
                </Form.Group>
                <Button
                  disabled={loading}
                  className="w-100 button-5"
                  type="submit"
                >
                  Sign Up
                </Button>
              </Form>
            </div>
            <div className="w-100 text-center mt-2">
              Already registered?{" "}
              <Link
                to="/login"
                className="button-5"
                style={{ marginLeft: "5px" }}
              >
                Login
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
