import { Divider, Grid, Typography } from "@mui/material"
import Box from '@mui/material/Box';
import axios from "axios"
import React,{useEffect,useState} from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../context/AuthContext"
import Error from "./Error"
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// import Box from '@mui/material/Box';
import YoutubeEmbed from "./YoutubeEmbed";


export default function Payment() {
    // const navigate = useNavigate()
  const {currentUser} = useAuth()
  // console.log(currentUser.photoURL)
  const [user,setUser] = useState()
  const [error,setError] = useState("")
  useEffect(() => {
      fetch("https://toscbackend.onrender.com/api/getuser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => response.json())
        .then((json) => {
          setUser(json);
        })
        .catch((err) => {
          console.log(err);
          setError("Error in fetching data...");
        });
    }, []);
  // console.log(currentUser)
  return (
    error? <Error message={error} solution='Please make sure you are connected to Internet.'/>:(
        user===undefined?<Box sx={{ display: 'block',textAlign:'center',marginTop:'100px' }}><CircularProgress 
      /></Box> :
    <div className="dashboard-gl">
    <Box>
    <div className="dashboard-card">
     <Typography className="hello-resp" variant="h2" sx={{color:'lightskyblue', display:'inline-block', marginBottom:'13px'}}>Payment Details</Typography>
      <Grid container>
        <Grid item xs={12} md={8} >
          <div className="dashboard-card">
          <Divider light={true} />
          
          Watch these instructions carefully before making any payment. <br />
          <div className="youtube-div-center"><YoutubeEmbed embedId="https://drive.google.com/file/d/1bCUPz9nj1VgjodbrdswOAyWwNAKvsLbO/preview"/>
          {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdNazn_8ADWEAGhv0g6Vy9POvBQcJNu9BoRza2zLu4NxVs8Kg/viewform?embedded=true" width="740" height="400" frameborder="0">Loading…</iframe> */}
          </div>
          <div className="text-center-payment" style={{ display: 'flex'}}><button className="button-5" style={{ whiteSpace: "nowrap", width: "700px",padding: "10px" }}><a href="https://docs.google.com/document/d/1u_ff7V5uYch6rFaNaWORXGxA8EjK_iuhQTJfXJ1MiVo/edit?usp=sharing" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>Read These instructions <br/> before making payment.</a></button></div>

          {/* <div className="text-center-payment-content" style={{color : "bisque"}}>Watch the above video for instructions. After your payment is done, make sure to take screenshot of payment receipt of SBI icollect.</div> */}

          <div className="text-center-payment" style={{margin: "10px"}}><button className="button-5"><a href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>Make Payment</a></button></div>
          {/* <div className="text-center-payment-content" style={{color : "bisque"}}>After successful payment, please go to the link below to upload the Payment Receipt.</div>
          <div className="text-center-payment" style={{margin: "10px"}}><button className="button-5" ><a href="https://docs.google.com/forms/d/e/1FAIpQLSdNazn_8ADWEAGhv0g6Vy9POvBQcJNu9BoRza2zLu4NxVs8Kg/viewform" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>Upload Receipt</a></button></div> */}
          {/* <h3 style={{color : "bisque"}}>For payment-related queries, feel free to contact the following:</h3> */}
          {/* <div class="flex-container">
          <div class = "button-5-red" >
          <h4>Sarthak: 7389491527</h4>
          </div>
          </div>
          <div class="flex-container">
          <div class = "button-5-red" style={{width:"210px"}}>
          <h4>Garvit: 8302479345</h4>
          </div>
          </div>
          <div class="flex-container">
          <div class = "button-5-red" style={{width:"210px"}}>
          <h4>Alok: 7355151136</h4>
          </div>
          </div>
          <div class="flex-container">
          <div class = "button-5-red" >
          <h4>Anurag: 8697010179</h4>
          </div>          
          </div> */}

          
          
          {/* <Grid container spacing={2} style={{padding:'20px 20px',wordWrap:'break-word'}}>
            <Grid item xs={6}> WhatsApp Number</Grid>
            <Grid item xs={6}> {user.whatsApp}</Grid>
          </Grid>
          <Grid container spacing={2} style={{padding:'20px 20px',wordWrap:'break-word'}}>
            <Grid item xs={6}> Email</Grid>
            <Grid item xs={6} > {user.email}</Grid>
          </Grid> */}
          </div>
        </Grid>
        {/* <Grid item xs={4}>
          <div style={{paddingTop: "15%"}} class="avatar-class">
            <Avatar className="avatar-resp" alt="name"src={currentUser.photoURL} sx={{width:200, height:200, marginLeft:'5px'}} />
          </div>
        </Grid> */}
      </Grid>

      {/* <Alert severity="info">

        <AlertTitle>Info</AlertTitle>
        <strong>Payment for TOSC'22 is not started yet. Payments will be started soon.</strong>
      </Alert> */}
      </div>
     </Box>
    </div>
    )
  )
}
