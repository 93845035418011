const top100StudentsPoolA = [
  {
    "name": "Aadarsh Singh",
    "email": "aadarshsingh729@gmail.com",
    "phone": "8709500302"
  },
  {
    "name": "Atik Nazir",
    "email": "atiknazir7@gmail.com",
    "phone": "9934280735"
  },
  {
    "name": "Nitya kumari",
    "email": "gudduk81747@gmail.com",
    "phone": "9234345270"
  },
  {
    "name": "Arya Mimamsa",
    "email": "aryamimamsa@gmail.com",
    "phone": "9955075454"
  },
  {
    "name": "Omsai Dharamveer Thakur",
    "email": "dthakur465@gmail.com",
    "phone": "9403062674"
  },
  {
    "name": "Payal kumari",
    "email": "satyamkumarkurtha2006@gmail.com",
    "phone": "8271547723"
  },
  {
    "name": "Raushani Kumari",
    "email": "raushani4051@gmail.com",
    "phone": "8294210132"
  },
  {
    "name": "V Vedha",
    "email": "vedhavasu@gmail.com",
    "phone": "9840412105"
  },
  {
    "name": "Abdul Haadhi R",
    "email": "rasheedfire@gmail.com",
    "phone": "9447519656"
  },
  {
    "name": "Rohit Kumar",
    "email": "rohitkumar25529.dpskalyanpur@gmail.com",
    "phone": "7376910002"
  },
  {
    "name": "SHRIVATS CHATTERJEE",
    "email": "shrivats1129@gmail.com",
    "phone": "8709793252"
  },
  {
    "name": "Debansi A Biswas",
    "email": "ak.biswas@hotmail.com",
    "phone": "8097498268"
  },
  {
    "name": "Madhav Sharma",
    "email": "rajeshsukhdev81@gmail.com",
    "phone": "9463288120"
  },
  {
    "name": "Indrasish Mukherjee",
    "email": "mukherjeesujit1969@gmail.com",
    "phone": "8777600294"
  },
  {
    "name": "Sreya Nair",
    "email": "doctoranjucnair@gmail.com",
    "phone": "9846563203"
  },
  {
    "name": "Ashwati S. Kartha",
    "email": "lekha7400@gmail.com",
    "phone": "9893094168"
  },
  {
    "name": "Akshika Sharma",
    "email": "rashmi.lasiyal@gmail.com",
    "phone": "7009620083"
  },
  {
    "name": "Purbayan Chatterjee",
    "email": "prithwipal@gmail.com",
    "phone": "9911000929"
  },
  {
    "name": "Aarna Singh",
    "email": "shiv.82.singh@gmail.com",
    "phone": "9670748000"
  },
  {
    "name": "Shreya Gupta",
    "email": "sangupta2005@rediffmail.com",
    "phone": "7568680251"
  },
  {
    "name": "Arnav Saxena",
    "email": "arnavsaxenakota@gmail.com",
    "phone": "8005817152"
  },
  {
    "name": "Raghav Panjwani",
    "email": "panjwanik@rocketmail.com",
    "phone": "8233077250"
  },
  {
    "name": "E.Abhipsa Rani",
    "email": "achintakothi2017@gmail.com",
    "phone": "8260885662"
  },
  {
    "name": "DALIYA MOHAMED SHAFI PK",
    "email": "shafipk6600@gmail.com",
    "phone": "9444666666"
  },
  {
    "name": "ABEER SHARMA",
    "email": "padamragguna@gmail.com",
    "phone": "9131692947"
  },
  {
    "name": "Yashi jain",
    "email": "kansalpreeti306@gmail.com",
    "phone": "7471138152"
  },
  {
    "name": "Ishaan Shukla",
    "email": "ishaanshukla12@gmail.com",
    "phone": "8081763082"
  },
  {
    "name": "PRATYUSH CHAUHAN",
    "email": "findkavita@hotmail.com",
    "phone": "8800256154"
  },
  {
    "name": "RACHIT RAJ",
    "email": "rachitraj1206@gmail.com",
    "phone": "9910057574"
  },
  {
    "name": "Bhavyarajsinh Vaghela",
    "email": "vaghelavanrajsinh@yahoo.com",
    "phone": "9978442025"
  },
  {
    "name": "Manya",
    "email": "uc.pathak@eil.co.in",
    "phone": "9910096406"
  },
  {
    "name": "Vihaan Garg",
    "email": "priyankagargniper@gmail.com",
    "phone": "9891168675"
  },
  {
    "name": "Krishna Rathore",
    "email": "sanjayrathore37369@gmail.com",
    "phone": "7000503288"
  },
  {
    "name": "Dipanshu Goyal",
    "email": "dipgoyalshs@gmail.com",
    "phone": "9430619187"
  },
  {
    "name": "Shivesh Yadav",
    "email": "yshivesh388@gmail.com",
    "phone": "6392684978"
  },
  {
    "name": "Mandla Dheeraj Reddy",
    "email": "mswetha2607@gmail.com",
    "phone": "8096499499"
  },
  {
    "name": "Abhiraj Gupta",
    "email": "abhirajguptas.h.s.123456789@gmail.com",
    "phone": "7209703588"
  },
  {
    "name": "Avinesh Goyal",
    "email": "avineshgoyal617@gmail.com",
    "phone": "8434258800"
  },
  {
    "name": "Srishti Garg",
    "email": "Garg.srishti05@gmail.com",
    "phone": "8527420636"
  },
  {
    "name": "Vansh Vardhan Singh",
    "email": "banku2907@gmail.com",
    "phone": "9602754224"
  },
  {
    "name": "Shristi Agrawal",
    "email": "shristiagrawal2010@gmail.com",
    "phone": "8920868252"
  },
  {
    "name": "ISHAN SHANKAR",
    "email": "is09082010@gmail.com",
    "phone": "8340509791"
  },
  {
    "name": "R. Jankesh Bhalaje",
    "email": "jankeshbhalaje@gmail.com",
    "phone": "9150771219"
  },
  {
    "name": "Anant pratap singh",
    "email": "anantsingh889618@gmail.com",
    "phone": "8182041911"
  },
  {
    "name": "Sanjiban Dev",
    "email": "Sanjitdev3112@gmail.com",
    "phone": "9143646091"
  },
  {
    "name": "zoya khan",
    "email": "skjpr2007@gmail.com",
    "phone": "9414266358"
  },
  {
    "name": "Rishaan Singh",
    "email": "rishaansingh793@gmail.com",
    "phone": "9794416896"
  },
  {
    "name": "Mihika patwa",
    "email": "meghapatwa87@gmail.com",
    "phone": "8269122965"
  },
  {
    "name": "Darsh Shaan Dudhia",
    "email": "madhavi.dudhia@gmail.com",
    "phone": "9879517413"
  },
  {
    "name": "Rishi Raj",
    "email": "rishiraj123682011@gmail.com",
    "phone": "7645928725"
  },
  {
    "name": "Md Saif Ali Khan",
    "email": "saniapatna@gmail.com",
    "phone": "8250825486"
  },
  {
    "name": "Ayusman Padhy",
    "email": "ayusmanpadhy10@gmail.com",
    "phone": "9439276545"
  },
  {
    "name": "Vidushi Srivastava",
    "email": "vimaljisrivastava@gmail.com",
    "phone": "7275593010"
  },
  {
    "name": "Divit Agrawal",
    "email": "divitec0@gmail.com",
    "phone": "9108357970"
  },
  {
    "name": "Avantika Agarwal",
    "email": "avantika.agarwal2505@gmail.com",
    "phone": "9821179961"
  },
  {
    "name": "Anuran Dan",
    "email": "dan.bankura@gmail.com",
    "phone": "9775560098"
  },
  {
    "name": "Anurag Kumar",
    "email": "anuragk8707@gmail.com",
    "phone": "6307890272"
  },
  {
    "name": "Shaurya Pratap Yadav",
    "email": "jhunnilalyadava@gmail.com",
    "phone": "9415150551"
  },
  {
    "name": "Zayan Humd",
    "email": "md.rh16@gmail.com",
    "phone": "6290239170"
  },
  {
    "name": "Payal kumari",
    "email": "satyamkumarkurtha2006@gmail.com",
    "phone": "8434880427"
  },
  {
    "name": "Harshit Kumar Sahoo",
    "email": "sahoo.kharshit@gmail.com",
    "phone": "8895729858"
  },
  {
    "name": "Ishaan jadon",
    "email": "Ishaanjadon551@gmail.com",
    "phone": "9610229254"
  },
  {
    "name": "Yashi Dwivedi",
    "email": "dwivediavadhesh@gmail.com",
    "phone": "6388377499"
  },
  {
    "name": "Jatin Singh",
    "email": "ss7047841@gmail.com",
    "phone": "8160023382"
  },
  {
    "name": "Tanvi Rajput",
    "email": "abhayp.singh3@gmail.com",
    "phone": "8527059067"
  },
  {
    "name": "Omansh Chadha",
    "email": "seema.chadha14@gmail.com",
    "phone": "9855711068"
  },
  {
    "name": "Dipanshu Goyal",
    "email": "dipgoyalshs@gmail.com",
    "phone": "9430619187"
  },
  {
    "name": "PIYUSH KUMAR",
    "email": "piyushkumarkurtha2008@gmail.com",
    "phone": "9661122015"
  },
  {
    "name": "Ayushi kumari",
    "email": "babykurtha@gmail.com",
    "phone": "9576561195"
  },
  {
    "name": "AYANNA SATYA",
    "email": "gauravsatya@yahoo.co.in",
    "phone": "7600436800"
  },
  {
    "name": "Pratyush Ranjan",
    "email": "pratyushranjan29101980@gmail.com",
    "phone": "9934072117"
  },
  {
    "name": "Arahan Teotia",
    "email": "pushpraj.teotia@gmail.com",
    "phone": "8447737262"
  },
  {
    "name": "Nial Girish Pandala",
    "email": "nial.girish@gmail.com",
    "phone": "9539613102"
  },
  {
    "name": "Ayushman Jay",
    "email": "aykumar14@gmail.com",
    "phone": "7006406352"
  },
  {
    "name": "Sarthak Gupta",
    "email": "shashisunita5381@gmail.com",
    "phone": "9411476223"
  },
  {
    "name": "Tejaswini Nagar",
    "email": "drshivcharannagar@gmail.com",
    "phone": "9784987564"
  },
  {
    "name": "Priyam Saxena",
    "email": "abhinavbmwr1250@gmail.com",
    "phone": "7049385536"
  },
  {
    "name": "Pallavi Krishna H",
    "email": "pallavikrishnah@gmail.com",
    "phone": "9497639220"
  },
  {
    "name": "Darshan Banerjee",
    "email": "darshan.banerjee.69@gmail.com",
    "phone": "9330889629"
  },
  {
    "name": "Saumya Vohra",
    "email": "smitamohanty08@gmail.com",
    "phone": "9899109272"
  },
  {
    "name": "Viswajith Biju",
    "email": "riyathusharam@gmail.com",
    "phone": "971 585733716"
  },
  {
    "name": "Neelima Uppot",
    "email": "anupuppot@gmail.com",
    "phone": "971 50 526 5373"
  },
  {
    "name": "Rakshita Singh",
    "email": "rajusingh6823@gmail.com",
    "phone": "8354897981"
  },
  {
    "name": "Chhavi Verma",
    "email": "chhapsverma@gmail.com",
    "phone": "9818149517"
  },
  {
    "name": "SUPRATIM DAS",
    "email": "tapan2174@gmail.com",
    "phone": "9.19435E+11"
  },
  {
    "name": "Swarnim Pandey",
    "email": "pandeyntpc@gmail.com",
    "phone": "9431600477"
  },
  {
    "name": "Kiyansha Rajpal",
    "email": "sonalirajpal007@gmail.com",
    "phone": "7737024965"
  },
  {
    "name": "Arpita Srivastava",
    "email": "nitinsrivastava144@gmail.com",
    "phone": "9026426953"
  },
  {
    "name": "Anshika Malhotra",
    "email": "pinkymalhotra8787@gmail.com",
    "phone": "9795983983"
  },
  {
    "name": "Rohith Rejeesh",
    "email": "indu.i2043@gmail.com",
    "phone": "8754254111"
  },
  {
    "name": "Suhavi kaur",
    "email": "jkkhurana007@gmail.com",
    "phone": "9417924120"
  },
  {
    "name": "Sattvik Gupta",
    "email": "shuchigupta14@gmail.com",
    "phone": "9599205118"
  },
  {
    "name": "Harsh Gupta",
    "email": "rgupta83@gmail.com",
    "phone": "9878006842"
  },
  {
    "name": "Ranbir Sahoo",
    "email": "bibhassahoo@gmail.com",
    "phone": "9831204528"
  },
  {
    "name": "Mohit raj",
    "email": "mohitraj098766@gmail.com",
    "phone": "7903251037"
  },
  {
    "name": "Abdulla Ansar",
    "email": "nagoor.ansar@gmail.com",
    "phone": "9995494442"
  }
];

export default top100StudentsPoolA;