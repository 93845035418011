import React, { useRef, useState } from "react"
import { Form, Button, Card} from "react-bootstrap"
import { useAuth } from "../context/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import logo from './Userlayout/TOSC_white.png';
import './login-styles.css';
import { alpha, styled } from '@mui/material/styles';
import { TextField } from "@mui/material";
import { FilledInput } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
// import Alert from '@mui/material/Alert';
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const CssTextField = styled(TextField)({
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#f0f0f0',
      color:'#000'
    },
    '& label.Mui-focused': {
      
      color: 'white',
      input:{
        color:"white"
    }
    },
    '& label.Mui': {
      
        color: 'white',
        input:{
          color:"white"
      }
      },
    '& .MuiInput-underline:after': {
      
      borderBottomColor: 'white',
      color:'white',
      input:{
        color:"white"
    }
    },
    '& .MuiFormLabel-root': {
      
        borderBottomColor: 'white',
        color:'grey',
        input:{
          color:"white"
      }
      },
    '& .MuiOutlinedInput-root': {
      
        input:{
          
            color:"white"
        },
      '& fieldset': {
        borderColor: 'grey',
        color:'white',
        
      },
      '&:hover fieldset': {
        borderColor: 'grey',
        color:'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
        color:'white',
        input:{
          
            color:"white"
        },
      },
      '&.MuiInputBase-input fieldset': {
        
        borderColor: 'white',
        color:'white',
        input:{
          
            color:"white"
        }
      },
    },
  });

export default function Login() {
    const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault()

      try {
        console.log("read");
        setError("");
        setOpen(false);
        setLoading(true);

        const response = await fetch(`${"https://toscbackend.onrender.com/api"}/signin`, {
            method: "POST",
            body: JSON.stringify({
                email: emailRef.current.value,
                password: passwordRef.current.value
            }),
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data = await response.json();

      if (!response.ok) {
        throw new Error(data.msg || "Failed to sign in");
      }

      console.log("working fire");
      localStorage.setItem("token", data.token);
      navigate("/dashboard");
    } catch (err) {
      console.error("Error:", err.message);
      setError(err.message);
      setOpen(true);
    } finally {
      setLoading(false);
    }
  }
  return (
    <div className="">
    <div className="login-gl">
    <a href = "https://tosc.techkriti.org"> <img src={logo} className="tosc_img tosc_img_signup" alt="tosc logo" /></a>
    {loading?<Box sx={{ display: 'block',textAlign:'center',marginTop:'100px' }}><CircularProgress 
      /></Box>:
      <>
        <h2 className="text-center">Log In</h2>
        {error && 
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%'}}>{error}</Alert>
        </Snackbar>
        }
          <div style={{textAlign: "center"}}>
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email" className="login-input">
              <Form.Label className="labelF">Email &nbsp;&nbsp;&nbsp;&nbsp;</Form.Label>
                   <CssTextField label="Email" id="" size="small" inputRef={emailRef} sx={{paddingLeft:'10px', paddingTop:'-10px'}} />
            </Form.Group>
            <Form.Group id="password" className="login-input">
              <Form.Label>Password</Form.Label>
              <CssTextField label="Password" type="password" id="custom-outlined-input" size="small" inputRef={passwordRef} sx={{paddingLeft:'10px'}} />
            </Form.Group>
            <Button disabled={loading} className="w-100 button-5" type="submit">
              Log In
            </Button>            
          </Form>
          </div>
          

        

          <div style={{textAlign:'center',marginTop: '10px'}}>
          <Link to="/forgot-password" className="button-5 forgot-button" style={{textDecoration:'none', margin: 'auto',color:'black', backgroundColor:'#33b5dc'}}>Forgot Password</Link>
        </div>  
        <div className="w-100 text-center mt-2">
          Haven't registered yet? <Link to="/signup" style={{textDecoration:'none'}}><button className="button-5">Register</button></Link>
        </div>
      </>

      }
      </div>
    </div>
  )
}
