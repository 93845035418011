import { Divider, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Error from "./Error";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import FormDetails from "./FormDetails";
import timeline from "./Userlayout/TOSC Poster.png";
// import Box from '@mui/material/Box';
import { Card, Button } from "react-bootstrap";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { CenterFocusStrong } from "@mui/icons-material";
import Certificate from "./Certificate";

export default function Dashboard() {
  // const navigate = useNavigate()
  // const {currentUser} = useAuth()
  // console.log(currentuser?.photoURL)
  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if(!localStorage.getItem("token")) {
      navigate('/login');
    } else {
      fetch("https://toscbackend.onrender.com/api/getuser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
      .then((response) => response.json())
      .then((json) => {
        setUser(json);
      })
      .catch((err) => {
        console.log(err);
        setError("Error in fetching data...");
      });
   }
  }, []);
  const m = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const release = new Date(user?.dob);
  const releaseDate = release.getDate() + ' ' + m[release.getMonth()] + ' ' + release.getFullYear();
  // console.log(currentUser)
  return error ? (
    <Error
      message={error}
      solution="Please make sure you are connected to Internet."
    />
  ) : user === undefined ? (
    <Box sx={{ display: "block", textAlign: "center", marginTop: "100px" }}>
      <CircularProgress />
    </Box>
  ) : (
    <div className="dashboard-gl">
      {!user?.isupdateprofile ? navigate('/update-profile') : null}
      {/* {!(user?.isupdateprofile) && <FormDetails></FormDetails>} */}

      {(user?.isupdateprofile) && <Box>
        <div className="dashboard-card">
          <Typography
            className="hello-resp"
            variant="h2"
            sx={{
              color: "#fff",
              display: "block",
              marginBottom: "40px",
              fontFamily: "cursive",
              textAlign: "center"
            }}
          >
            Hello     <Typography   variant="h2"
            sx={{ color: "#76ddff", display: "inline-block",fontFamily: "cursive" }}
          >
            {user?.name}!
          </Typography>
       
          </Typography>
          <Grid container>
            <Grid item xs={12} md={8}>
              <div className="dashboard-card">
                <Card  >  <Typography  sx={{fontFamily: "cursive"}}>

                  <strong style={{color:"#76ddff" ,marginLeft:"2%"}} >Name : </strong>  {user?.name} <br /> <br />
                  <strong style={{color:"#76ddff",marginLeft:"2%" }} >Parent Name: </strong> {user?.parentName} <br /><br />
                  <strong style={{color:"#76ddff" ,marginLeft:"2%"}}>Gender: </strong> {user?.gender} <br /><br />
                  <strong style={{color:"#76ddff",marginLeft:"2%" }}>Pool :</strong> {user?.pool} <br /><br />
                  <strong style={{color:"#76ddff",marginLeft:"2%" }}>Grade :</strong> {user?.grade} <br /><br />
                  <strong style={{color:"#76ddff",marginLeft:"2%" }}>Email :</strong> {user?.email} <br /><br />
                  <strong style={{color:"#76ddff" ,marginLeft:"2%"}}>Phone :</strong> {user?.phone} <br /><br />
                  <strong style={{color:"#76ddff" ,marginLeft:"2%"}}>DOB :&nbsp; </strong> {releaseDate} <br /> <br />
                  <strong style={{color:"#76ddff" ,marginLeft:"2%"}}>School Name :&nbsp; </strong> {user?.schoolName} <br /><br />
                  <strong style={{color:"#76ddff",marginLeft:"2%" }}>School Address :</strong> {user?.schoolAddress} <br /><br />
                  <strong style={{color:"#76ddff",marginLeft:"2%" }}>School City:&nbsp; </strong> {user?.schoolCity} <br /><br />
		      {/*<strong style={{color:"#76ddff",marginLeft:"2%" }}>Payment Status:</strong> {user?.ispayment ? "Paid" : "Pending"} <br /><br /><br /><br /><br /><br /><br />*/}
                  {/* <strong>Payment Done: </strong>  {user?.isPayment?"Yes":"No"} <br/> */}
                  {/* <Button onClick={handleLogout}> Logout</Button> */} </Typography>
                </Card>
              </div>
            </Grid>
            {/* <Grid item xs={4}>
              <div style={{ paddingTop: "15%" }} className="avatar-class">
              </div>
            </Grid> */}
          </Grid>
          {/* <img src={timeline} /> */}
          {!(user?.ispayment) && 
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            <center>
            <button className="button-5">
              <Link to="/payment">Click to Pay (Rs. 150)</Link>
            </button>
            </center>
            <br/>
            <strong>If you have made the transaction and amount is deducted from your account, please don't pay again.</strong>
            
          </Alert>
            }
          {/* <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            <strong>The payment link will be activated soon</strong>
          </Alert> */}
        </div>
      </Box>}
      {/* <Certificate /> */}
    </div>
  );
}
