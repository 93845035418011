import { Card, Divider, Grid, Typography } from "@mui/material"
import Box from '@mui/material/Box';
import React from "react"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import DownloadIcon from '@mui/icons-material/Download';
import '../Previous.css';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function Previous() {
  return (
    <div className="pyq-gl">
    <Box>
    <div className="dashboard-card" >
     <Typography variant="h2" sx={{textAlign:'center', marginBottom:4}}>Previous Year Papers</Typography>
      <div class="container">
			<div class="card">
				<div class="content">
					<div class="">
            <h3>POOL A</h3>
					</div>
					<div class="contentBx">
						<h3><span>Class 6th to 8th</span></h3>
					</div>
				</div>
				<ul class="sci">
					<li>
						<button class="button-5 button-prev"><a href="Pool-A.pdf" download>Download</a></button>
					</li>
				</ul>
			</div>
			<div class="card">
      <div class="content">
					<div class="">
            <h3>POOL B</h3>
					</div>
					<div class="contentBx">
						<h3><span>Class 9th to 10th</span></h3>
					</div>
				</div>
				<ul class="sci">
        <li>
						<button class="button-5 button-prev"><a href="Pool-B.pdf" download>Download</a></button>
					</li>
				</ul>
			</div>
			<div class="card">
      <div class="content">
					<div class="">
            <h3>POOL C</h3>
					</div>
					<div class="contentBx">
						<h3><span>Class 11th to 12th</span></h3>
					</div>
				</div>
				<ul class="sci">
        <li>
						<button class="button-5 button-prev"><a href="Pool-C.pdf" download>Download</a></button>
					</li>
				</ul>
			</div>
		</div>
      </div>
     </Box>
    </div>
  )
}